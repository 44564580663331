import React from 'react'
import { Icon } from "@alifd/theme-14191";
import './index.scss'


class OverlayTitle extends React.Component {

    render() {
        const { closeDialog, title } = this.props;
        return (
            <div className="header" >
                <div className="title">{title}</div>
                <Icon className="close" size="xs" type="close" onClick={closeDialog} />
            </div>
        )
    }
}

export default OverlayTitle;
