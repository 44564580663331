
import React from 'react';
import { Overlay, Icon, Grid, Form, Input, Select, Button } from '@alifd/theme-14191';
import './index.scss'

import OverlayTitle from '../../../../Common/OverlayTitle'
import OverlayFooter from '../../../../Common/OverlayFooter'
import { getMainContainer } from '../../../../Common/common';

//apis
import { getModelList, getMaterialsList, getPeopleList, doCoffee } from '../../../../../config/axios/apis'

const { Row, Col } = Grid;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { fixedSpan: 5 }
};
const materialsLayout = (that, value, index) => {
    const { coffee, materialList } = that.state;
    const key = 'material' + index;
    return (
        <Col span={11}>
            <Row>
                <Col span={18}>
                    <FormItem size="large" label="物料" {...formItemLayout} required requiredMessage="必填">
                        <Select name={key} value={coffee ? coffee[key] : ''} style={{ width: '100%' }} value={value} onChange={(value) => { that.selectMaterials(value, index) }}>
                            {/* <Select.Option value="物料1">物料1</Select.Option>
                            <Select.Option value="物料4">物料4</Select.Option>
                            <Select.Option value="物料3">物料3</Select.Option> */}
                            {
                                materialList.map((item) => {
                                    return (
                                        <Select.Option value={item.id} key={index}>{item.materiel_name}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </FormItem>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                    {
                        index == 0 ?
                            <Button type="secondary" size="large" className="addmaterial" onClick={that.addMaterials}>增加</Button> :
                            <Button type="secondary" size="large" className="addmaterial" warning onClick={() => that.removeMaterials(index)}>删除</Button>
                    }
                </Col>
            </Row>
        </Col>
    )
}
class EditCoffee extends React.Component {

    // 生成二维码，查看二维码，下载二维码 已删除

    constructor() {
        super()
        this.state = {
            materials: [""],//物料
            container: '',
            qrcode: "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg",//二维码
            coffee: {},
            //apis
            add: false,
            modelList: [],//型号列表
            materialList: [],//物料列表
            peopleList: [],//人员列表
        }
    }

    //获取型号列表
    getAllModel = () => {
        getModelList('', '', 2).then((res) => {
            let dataSource = []
            res.data.forEach((item, index) => {
                dataSource.push({
                    id: item.id,
                    number: index + 1,
                    model: item.model_name,//"机型：" + i
                })
            })
            this.setState({
                modelList: dataSource,
            })
        })
    }
    //获取物料
    getAllMaterial = () => {
        getMaterialsList('', '', '', 2).then((res) => {
            if (res.data) {
                var materials = [];
                res.data.forEach((item) => {
                    materials.push(item);
                })
                this.setState({
                    materialList: materials
                })
            }
        })
    }

    //获取人员列表
    getAllPeople = () => {
        getPeopleList('', '', '', 2).then((res) => {
            if (res.data) {
                var tempAll = [];
                res.data.forEach((item, index) => {
                    tempAll.push({
                        number: index + 1,
                        id: item.id,
                        department: item.branch,
                        name: item.name,
                        phone: item.phone,
                        allnum: item.machine_count,//parseInt(100 * (Math.random())),
                    })
                })
                this.setState({
                    peopleList: tempAll,
                })
            }
        })
    }

    componentDidMount() {
        this.getAllPeople();
        this.getAllMaterial();
        this.getAllModel();
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let coffee = "";
        let add = true;
        let materials = [""]
        if (nextProps.editCoffee && nextProps.coffeeModel) {
            coffee = JSON.parse(JSON.stringify(nextProps.coffeeModel));
            // console.log(coffee)
            if (coffee.materiel_ids) {
                materials = coffee.materiel_ids.split(',')
                materials.map((item, index) => {
                    coffee['material' + index] = item;
                })
            }
            add = false;
        }
        this.setState({
            coffee,
            materials,
            add
        })
    }

    //选择物料
    selectMaterials = (value, index) => {
        var materials = this.state.materials;
        materials[index] = value;
        this.setState({
            materials
        })
        this.changeInfo(value, 'material' + index)
    }

    //删除物料
    removeMaterials = (index) => {
        var materials = this.state.materials;
        materials.splice(index, 1);
        this.setState({
            materials
        })
    }
    //添加物料
    addMaterials = () => {
        var materials = this.state.materials;
        materials.push("");
        this.setState({
            materials
        })
    }

    //渲染增加materials
    renderAddMaterials = (pos) => {
        if (pos == 0) {
            return materialsLayout(this, this.state.materials[0], 0)
        }
        if (this.state.materials.length <= 1) return;
        if (pos == 1) {
            return materialsLayout(this, this.state.materials[1], 1);
        }
        return this.state.materials.map((item, index) => {
            if (index < 2) return "";
            return (
                <Row key={index}>
                    <Col span={11}></Col>
                    <Col span={2} />
                    {
                        materialsLayout(this, item, index)
                    }
                </Row>
            )
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        let coffee = this.state.coffee;
        if (!coffee) coffee = {};
        coffee[key] = value
        this.setState({
            coffee
        })
    }

    //保存
    handleSubmit = (values, errors) => {
        if (errors) { return }
        let act = "add";
        let id = ""
        if (!this.state.add) {
            act = "edit";
            id = this.state.coffee.id
        }
        
        doCoffee(act, id, values.model, values.modelnum, values.cuptype, values.price, values.area, values.customer, values.address, values.name, values.phone, values.head, this.state.materials.join(',')).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog()
            }
        }).catch((ex) => {
            console.error(ex)
        })
    }

    render() {
        const { editCoffee, closeDialog } = this.props;
        const { container, qrcode, coffee, modelList, peopleList } = this.state;
        return (
            <Overlay title="新增咖啡机"
                visible={editCoffee}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >

                <Form className="edit-coffee">
                    {/* 顶部 */}
                    <OverlayTitle title="新增咖啡机" closeDialog={closeDialog} />
                    {/* 内容 */}
                    <div className="content">
                        {/* 左半边 */}
                        <div style={{ width: '80%' }}>
                            {/* <Form> */}
                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="机型" {...formItemLayout} required requiredMessage="必填">
                                        <Select name="model" onChange={(value) => this.changeInfo(value, 'model_id')} value={coffee ? coffee.model_id : ''} style={{ width: '100%' }}>
                                            {
                                                modelList.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item.id} key={index}>{item.model}</Select.Option>
                                                    )
                                                })
                                            }
                                            {/* <Select.Option value="11111">1111</Select.Option>
                                            <Select.Option value="22222">2222</Select.Option>
                                            <Select.Option value="33333">3333</Select.Option> */}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                <Col span={11}>
                                    <FormItem size="large" label="地址" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="address" onChange={(value) => this.changeInfo(value, 'address')} value={coffee ? coffee.address : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="机型编号" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="modelnum" onChange={(value) => this.changeInfo(value, 'modelnum')} value={coffee ? coffee.modelnum : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                <Col span={11}>
                                    <FormItem size="large" label="联系人" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="name" onChange={(value) => this.changeInfo(value, 'name')} value={coffee ? coffee.name : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="杯型" {...formItemLayout} required requiredMessage="必填">
                                        <Select name="cuptype" onChange={(value) => this.changeInfo(value, 'cuptype')} value={coffee ? coffee.cuptype : ""} style={{ width: '100%' }}>
                                            <Select.Option value="大杯">大杯</Select.Option>
                                            {/* <Select.Option value="中杯">中杯</Select.Option> */}
                                            <Select.Option value="小杯">小杯</Select.Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                <Col span={11}>
                                    <FormItem size="large" label="联系电话" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="phone" onChange={(value) => this.changeInfo(value, 'phone')} value={coffee ? coffee.phone : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="单价" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="price" onChange={(value) => this.changeInfo(value, 'price')} value={coffee ? coffee.price : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                <Col span={11}>
                                    <FormItem size="large" label="归属人" {...formItemLayout} required requiredMessage="必填">
                                        {/* peopleList */}
                                        <Select name="head" onChange={(value) => this.changeInfo(value, 'belonger_id')} value={coffee ? coffee.belonger_id : ''} style={{ width: '100%' }}>
                                            {
                                                peopleList.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                            {/* <Select.Option value="11111">1111</Select.Option>
                                            <Select.Option value="22222">2222</Select.Option>
                                            <Select.Option value="33333">3333</Select.Option> */}
                                        </Select>
                                        {/* <Input name="head" onChange={(value) => this.changeInfo(value, 'head')} value={coffee ? coffee.head : ""} placeholder="请输入" /> */}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="地区" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="area" onChange={(value) => this.changeInfo(value, 'area')} value={coffee ? coffee.area : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                {
                                    this.renderAddMaterials(0)
                                }
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <FormItem size="large" label="客户" {...formItemLayout} required requiredMessage="必填">
                                        <Input name="customer" onChange={(value) => this.changeInfo(value, 'customer')} value={coffee ? coffee.customer : ""} placeholder="请输入" />
                                    </FormItem>
                                </Col>
                                <Col span={2} />
                                {
                                    this.renderAddMaterials(1)
                                }
                            </Row>
                            {
                                this.renderAddMaterials()
                            }
                            {/* </Form> */}
                        </div>
                        {/* 右半边 */}
                        {/* <div style={{ width: '30%' }}>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col span={7}>
                                </Col>
                                <Col span={8}>
                                    <Button size="large" type="primary">生成二维码</Button>
                                </Col>
                                <Col span={7}>
                                </Col>
                            </Row>
                            <div className="qrcode">
                                {
                                    qrcode ? <img style={{ width: "100%", height: "100%" }} src={qrcode} /> : <div className="tip">此处生成二维码</div>
                                }
                            </div>
                            <Row>
                                <Col span={7}>
                                </Col>
                                <Col span={8}>
                                    <Button size="large" type="primary">下载二维码</Button>
                                </Col>
                                <Col span={7}>
                                </Col>
                            </Row>
                        </div> */}
                    </div>

                    <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                </Form>
            </Overlay>
        )
    }
}

export default EditCoffee