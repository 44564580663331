import {
    Ajax
} from './index'

const method = {
    post: 'POST',
    get: 'GET'
}

/**
 * 登录
 * @param {String} username 
 * @param {String} password 
 */
export const login = (data) => {
    return Ajax({
        url: '/admin/Login/login',
        method: method.post,
        data: data
    })
}


// #region 订单管理

/**
 * 获取订单列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getOrderList = (page = 1, limit = 15, data = {}) => {
    return Ajax({
        url: '/admin/Order/getOrderList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            ...data
        }
    })
}

export const getOrderRemindList = (page = 1, limit = 15, data = {}) => {
    return Ajax({
        url: '/admin/Order/getOrderRemindList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            ...data
        }
    })
}

/**
 * 订单状态修改
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const orderVerify = (order_id, order_status) => {
    return Ajax({
        url: '/admin/Order/orderVerify',
        method: method.get,
        params: {
            order_id,
            order_status
        }
    })
}

// #endregion

// #region 邀请码管理

/**
 * 获取邀请码列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getApplyCodeList = (page = 1, limit = 15, status = '', code_create_time = '') => {
    return Ajax({
        url: '/admin/Launch/getApplyCodeList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            status,
            code_create_time
        }
    })
}

/**
 * 修改备注
 */
export const editRemarkCode = (id, remark) => {
    return Ajax({
        url: '/admin/Launch/editRemarkCode',
        method: method.get,
        params: {
            id,
            remark
        }
    })
}

/**
 * 删除邀请码
 */
export const delCode = (id) => {
    return Ajax({
        url: '/admin/Launch/delCode',
        method: method.get,
        params: {
            id,
        }
    })
}


/**
 * 生成邀请码
 */
export const createCode = (discount_id, num) => {
    return Ajax({
        url: '/admin/Launch/createCode',
        method: method.get,
        params: {
            discount_id,
            num
        }
    })
}


// #endregion

// #region 折扣管理

/**
 * 获取折扣列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getDiscountList = (page = 1, limit = 15, type = 1) => {
    return Ajax({
        url: '/admin/Launch/getDiscountList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            type
        }
    })
}

/**
 * 折扣的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 折扣id
 * @param {*} discount 折扣
 */
export const doDiscount = (act, id, discount) => {
    return Ajax({
        url: '/admin/Launch/doDiscount',
        method: method.post,
        data: {
            act,
            id,
            discount
        }
    })
}

// #endregion

// #region 投放规则管理

/**
 * 获取投放规则列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 * @param {*} launch_type 1 包月 2包次
 * @param {*} type 1 分页 0不分页
 */
export const getLauchList = (launch_type, page = 1, limit = 15, type = 1) => {
    return Ajax({
        url: '/admin/Launch/getLauchList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            launch_type,
            type
        }
    })
}


/**
 * 投放规则的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doLauch = (act, id, frequency, give_frequency, amount) => {
    return Ajax({
        url: '/admin/Launch/doLauch',
        method: method.post,
        data: {
            act,
            id,
            frequency,
            give_frequency,
            amount
        }
    })
}

// #endregion

// #region 广告机管理


/**
 * 获取广告机列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getKarolList = (page = 1, limit = 15, shop = "", region = "", layer = "", type = 1) => {
    return Ajax({
        url: '/admin/Karol/getKarolList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            shop,
            region,
            layer,
            type
        }
    })
}

/**
 * 获取广告机筛选数据
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const karoldScreen = () => {
    return Ajax({
        url: '/admin/Karol/karoldScreen',
        method: method.get,
        params: {}
    })
}



/**
 * 广告机的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doKarol = (act, id, shop, region, layer, karol_number, remark, is_fault) => {
    return Ajax({
        url: '/admin/Karol/doKarol',
        method: method.post,
        data: {
            act,
            id,
            shop,
            region,
            layer,
            karol_number,
            remark,
            is_fault
        }
    })
}

// #endregion 

// #region 合作伙伴管理


/**
 * 获取合作伙伴列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getPartnersList = (page = 1, limit = 15, search = '') => {
    return Ajax({
        url: '/admin/Person/getPartnersList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            search
        }
    })
}


/**
 * 合作伙伴的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doPartners = (act, id, shop_uname, uname, password) => {
    return Ajax({
        url: '/admin/Person/doPartners',
        method: method.post,
        data: {
            act,
            id,
            shop_uname,
            uname,
            password
        }
    })
}

// #endregion

// #region 加盟审核管理



/**
 * 获取加盟审核列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getJoinList = (page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Caseli/getJoinList',
        method: method.get,
        params: {
            page: page,
            limit: limit
        }
    })
}


/**
 * 回访
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const joinReturn = (id) => {
    return Ajax({
        url: '/admin/Caseli/joinReturn',
        method: method.get,
        params: {
            id
        }
    })
}

// #endregion

// #region 案例管理


/**
 * 获取案例列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getCaseList = (page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Caseli/getCaseList',
        method: method.get,
        params: {
            page: page,
            limit: limit
        }
    })
}


/**
 * 案例的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doCase = (act, id, case_title, case_content, sort, karol_info, resources, shop_uname, cover_img) => {
    return Ajax({
        url: '/admin/Caseli/doCase',
        method: method.post,
        data: {
            act,
            id,
            case_title,
            case_content,
            sort,
            karol_info,
            resources,
            shop_uname,
            cover_img
        }
    })
}

// #endregion

// #region 轮播图设置


/**
 * 获取轮播图列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getBannerList = (page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Caseli/getBannerList',
        method: method.get,
        params: {
            page: page,
            limit: limit
        }
    })
}


/**
 * 轮播图的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doBanner = (act, id, banner_img) => {
    return Ajax({
        url: '/admin/Caseli/doBanner',
        method: method.post,
        data: {
            act,
            id,
            banner_img
        }
    })
}

// #endregion

// #region 推荐机位管理


/**
 * 获取推荐及列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getKarolRecommendList = (page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Karol/getKarolRecommendList',
        method: method.get,
        params: {
            page: page,
            limit: limit
        }
    })
}


/**
 * 轮播图的增删改查
 * @param {*} act add / edit / del
 * @param {*} id 投放规则id
 * @param {*} frequency 次数
 * @param {*} give_frequency 赠送次数
 * @param {*} amount 金额
 */
export const doKarolRecommend = (act, id, k_id, shop, shop_info, karol_img) => {
    return Ajax({
        url: '/admin/Karol/doKarolRecommend',
        method: method.post,
        data: {
            act,
            id,
            k_id,
            shop,
            shop_info,
            karol_img
        }
    })
}

// #endregion

// #region 上传文件

/**
 * 获取案例列表
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const uploadFile = (file) => {
    return Ajax({
        url: '/admin/Caseli/upload_file',
        method: method.post,
        data: file
    })
}

// #endregion

// #region 数据统计


/**
 * 财务统计
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getFinancialList = (start_time, end_time, order_status, launch_id, page = 1, limit = 15, excel = false) => {
    if (!excel) {
        return Ajax({
            url: '/admin/Statistics/getFinancialList',
            method: method.get,
            params: {
                page: page,
                limit: limit,
                start_time,
                end_time,
                order_status,
                launch_id,
            }
        })
    } else {
        window.open(`https://lk.dysense.cn/admin/Statistics/getFinancialList?page=${page}&limit=${limit}&start_time=${start_time}&end_time=${end_time}&order_status=${order_status}&launch_id=${launch_id}&excel=1`)
    }
}


/**
 * 投放次数统计
 * @param {*} page 默认1
 * @param {*} limit 默认15
 */
export const getlaunchStatisticsList = (start_time = "", end_time = "", shop = "", region = "", layer = "", page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Statistics/getlaunchStatisticsList',
        method: method.get,
        params: {
            page: page,
            limit: limit,
            start_time,
            end_time,
            shop,
            region,
            layer
        }
    })
}


// #endregion














//#region 人员管理

/**
 * 获取人员列表
 * @param {*} search 模糊查询
 * @param {*} page 默认1
 * @param {*} limit 默认15
 * @param {*} type 	1 分页 2不分页获取全部
 */
export const getPeopleList = (search = '', page = 1, limit = 15, type = 1) => {
    return Ajax({
        url: '/admin/Staff/getStaffList',
        method: method.get,
        params: {
            search: search,
            page: page,
            limit: limit,
            type: type
        }
    })
}

/**
 * 人员管理增删改
 * @param {*} act add / edit / del
 * @param {*} id 人员id 修改删除不为空 新增为空
 * @param {*} branch 部门
 * @param {*} name 名称
 * @param {*} phone 手机号
 */
export const doPeople = (act, id, branch = '', name = '', phone = '') => {
    return Ajax({
        url: '/admin/Staff/doStaff',
        method: method.post,
        data: {
            act: act,
            id: id,
            branch: branch,
            name: name,
            phone: phone
        }
    })
}

/**
 * 人员设备管理详情
 * @param {*} id 员工id
 */
export const getPeopleMachine = (id) => {
    return Ajax({
        url: '/admin/Staff/getStaffMachineList',
        method: method.get,
        params: {
            staff_id: id
        }
    })
}

//#endregion

//#region 咖啡机管理

/**
 * 查看二维码
 * @param {*} id 	咖啡机id
 */
export const seeQrcode = (machine_number, url) => {
    return Ajax({
        url: '/admin/Qrcode/qrcode',
        method: method.get,
        params: {
            machine_number,
            url
        }
    })
}

/**
 * 获取型号列表
 * @param {*} page 
 * @param {*} limit 
 * @param {*} type 1 分页 2不分页
 */
export const getModelList = (page = 1, limit = 15, type = 1) => {
    return Ajax({
        url: '/admin/Machine/getModelList',
        method: method.get,
        params: {
            page,
            limit,
            type
        }
    })
}

/**
 * 型号增删改
 * @param {*} act add / edit / del
 * @param {*} id 型号id
 * @param {*} model_name 型号名称
 */
export const doModel = (act, id, model_name) => {
    return Ajax({
        url: '/admin/Machine/doModel',
        method: method.post,
        data: {
            act,
            id,
            model_name
        }
    })
}


/**
 * 获取咖啡机列表
 * @param {*} belonger_id 归属人id
 * @param {*} search 编号搜索
 * @param {*} page 
 * @param {*} limit 
 * @param {*} excel 0 默认为0 1 导出excel
 */
export const getCoffeeList = (belonger_id = '', search = '', page = 1, limit = 15, excel = 0) => {
    return Ajax({
        url: '/admin/Machine/getMachineList',
        method: method.get,
        params: {
            belonger_id: belonger_id,
            search: search,
            page: page,
            limit: limit,
            excel: excel
        }
    })
}

/**
 * 咖啡机增删改
 * @param {*} act add / edit / del
 * @param {*} id 机器id
 * @param {*} model_id 型号id
 * @param {*} machine_number 机器编号
 * @param {*} cup_shape 杯型 小杯 / 大杯
 * @param {*} unit_price 单价
 * @param {*} region 	地区
 * @param {*} customer 	客户
 * @param {*} address_info 详细地址
 * @param {*} contacts 联系人
 * @param {*} contact_tel 联系电话
 * @param {*} belonger_id 	归属人id(员工id)
 * @param {*} materiel_ids 物料id (多个用逗号拼接成字符串)
 */
export const doCoffee = (act, id, model_id, machine_number, cup_shape, unit_price, region, customer, address_info, contacts, contact_tel, belonger_id, materiel_ids) => {
    // console.log(act, id, model_id, machine_number, cup_shape, unit_price, region, customer, address_info, contacts, contact_tel, belonger_id, materiel_ids)
    return Ajax({
        url: '/admin/Machine/doMachine',
        method: method.post,
        data: {
            act,
            id,
            model_id,
            machine_number,
            cup_shape,
            unit_price,
            region,
            customer,
            address_info,
            contacts,
            contact_tel,
            belonger_id,
            materiel_ids
        }
    })
}

//#endregion

//#region 物料信息

/**
 * 物料列表
 * @param {*} search 
 * @param {*} page 
 * @param {*} limit 
 * @param {*} type 1 分页 2不分页
 */
export const getMaterialsList = (search = '', page = 1, limit = 15, type = 0) => {
    return Ajax({
        url: '/admin/Materiel/getMaterielList',
        method: method.get,
        params: {
            search,
            page,
            limit,
            type
        }
    })
}

/**
 * 物料增删改
 * @param {*} act add / edit / del
 * @param {*} id 物料id 新增为空
 * @param {*} materiel_name 物料名称
 * @param {*} cost_price 成本价
 */
export const doMaterial = (act, id, materiel_name, cost_price) => {
    return Ajax({
        url: '/admin/Materiel/doMateriel',
        method: method.post,
        data: {
            act,
            id,
            materiel_name,
            cost_price
        }
    })
}

//#endregion

//#region 物料管理

/**
 * 获取物料管理的列表
 * @param {*} search 
 * @param {*} page 
 * @param {*} limit 
 */
export const getMaterielStaffList = (search = '', page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Materiel/getMaterielStaffList',
        method: method.get,
        params: {
            search,
            page,
            limit
        }
    })
}

/**
 * 获取用户物料详情
 * @param {*} staff_id 	员工id
 */
export const getMaterielStaffInfo = (staff_id) => {
    return Ajax({
        url: '/admin/Materiel/getMaterielStaffInfo',
        method: method.get,
        params: {
            staff_id,
        }
    })
}

/**
 * 物料数量编辑修改
 * @param {*} staff_id 员工id
 * @param {*} json 例子格式 [{"id":1,"num":10},{"id":2,"num":5}](id:物料id num修改后的数量))
 */
export const editMaterialNum = (staff_id, json) => {
    return Ajax({
        url: '/admin/Materiel/editMaterielNum',
        method: method.post,
        data: {
            staff_id,
            json
        }
    })
}


/**
 * 获取审核批列表
 * @param {*} page 
 * @param {*} limit 
 */
export const getMaterielApprovalList = (page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Materiel/getMaterielApprovalList',
        method: method.get,
        data: {
            page,
            limit
        }
    })
}

/**
 * 审批通过/驳回
 * @param {*} id 申请id
 * @param {*} is_approval 	1 通过 2驳回
 */
export const doMaterielApproval = (id, is_approval) => {
    return Ajax({
        url: '/admin/Materiel/doMaterielApproval',
        method: method.post,
        data: {
            id,
            is_approval
        }
    })
}

//#endregion

//#region 维修管理

/**
 * 获取维护列表
 * @param {*} search 
 * @param {*} receive_staff_id 	维修人id
 * @param {*} page 
 * @param {*} limit 
 */
export const getRepairList = (search = '', receive_staff_id = '', page = 1, limit = 15, start_time = '', end_time = '') => {
    return Ajax({
        url: '/admin/Materiel/getRepairList',
        method: method.get,
        params: {
            search,
            receive_staff_id,
            page,
            limit,
            start_time,
            end_time
        }
    })
}

/**
 * 删除维护
 * @param {*} id 维护单的id
 */
export const delRepair = (id) => {
    return Ajax({
        url: '/admin/Materiel/delRepair',
        method: method.post,
        data: {
            id
        }
    })
}

/**
 * 获取审批列表
 */
export const getRepairApproval = () => {
    return Ajax({
        url: '/admin/Materiel/getRepairApproval',
        method: method.get,
    })
}

/**
 * 审批分配
 * @param {*} id 维修单id
 * @param {*} receive_staff_id 员工id 分配给的员工id
 */
export const distribution = (id, receive_staff_id) => {
    return Ajax({
        url: '/admin/Materiel/distribution',
        method: method.post,
        data: {
            id,
            receive_staff_id
        }
    })
}

//#endregion


//#region 设备地图

/**
 * 获取设备地图列表
 * @param {*} search 
 * @param {*} belonger_id 归属人id
 */
export const getEquipmentMapList = (search, belonger_id) => {
    return Ajax({
        url: '/admin/Materiel/getEquipmentMapList',
        method: method.get,
        params: {
            search,
            belonger_id
        }
    })
}

//#endregion

//#region 运营统计

/**
 * 获取运营数据
 * @param {*} type  1 今天 2明天 3本周 4上周 5本月 6上月
 */
export const getOperateCount = (type) => {
    return Ajax({
        url: '/admin/Operate/getOperateCount',
        method: method.get,
        params: {
            type
        }
    })
}

/**
 * 运营数据汇总
 * @param {*} search 机器编号筛选
 * @param {*} start_time 开始时间戳
 * @param {*} end_time 	结束时间戳
 * @param {*} page 
 * @param {*} limit 
 * @param {*} excel 
 */
export const getOperateList = (search, start_time, end_time, page = 1, limit = 15, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getOperateList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            page,
            limit,
            excel
        }
    })
}


export const getRepairList_Data = (search, start_time, end_time, page = 1, limit = 15, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getRepairList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            page,
            limit,
            excel
        }
    })
}


/**
 * 机型分析
 * @param {*} search 	型号名称
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} excel 
 */
export const getModelCountList = (search, start_time, end_time, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getModelCountList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            excel
        }
    })
}


/**
 * 获取月份运营分析汇总数据
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} page 
 * @param {*} limit 
 */
export const getMonthOperateList = (search, start_time, end_time, page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Operate/getMonthOperateList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            page,
            limit
        }
    })
}

/**
 * 获取 总计 平均 / excel
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} excel 
 */
export const getMonthOperateExcel = (search, start_time, end_time, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getMonthOperateExcel',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            excel
        }
    })
}



/**
 * 获取月份维修汇总列表
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} page 
 * @param {*} limit 
 */
export const getMonthRepairList = (search, start_time, end_time, page = 1, limit = 15) => {
    return Ajax({
        url: '/admin/Operate/getMonthRepairList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            page,
            limit
        }
    })
}

/**
 * 获取 总计 平均 / excel
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} excel 
 */
export const getMonthRepairExcel = (search, start_time, end_time, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getMonthRepairExcel',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            excel
        }
    })
}

/**
 * 获取客户统计列表
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} page 
 * @param {*} limit 
 */
export const getCustomerCountList = (search, start_time, end_time, page = 1, limit = 15, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/getCustomerCountList',
        method: method.get,
        params: {
            search,
            start_time,
            end_time,
            page,
            limit,
            excel
        }
    })
}

/**
 * 机器年度运营
 * @param {*} search 
 * @param {*} year 
 * @param {*} excel 
 */
export const getYearOperate = (search, year, excel) => {
    return Ajax({
        url: '/admin/Operate/machine_Annual_operation_analysis',
        method: method.get,
        params: {
            search,
            year,
            excel
        }
    })
}

/**
 * 原物料分析汇总
 * @param {*} start_time 
 * @param {*} end_time 
 * @param {*} excel 
 */
export const getRawMaterial = (start_time, end_time, excel = 0) => {
    return Ajax({
        url: '/admin/Operate/Raw_material_analysis_summary',
        method: method.get,
        params: {
            start_time,
            end_time,
            excel
        }
    })
}

/**
 * 员工物料库存数据列表
 * @param {*} search 
 * @param {*} start_time 
 * @param {*} end_time 
 */
export const getStaffMaterielCountList = (search, start_time, end_time) => {
    return Ajax({
        url: '/admin/Operate/getStaffMaterielCountList',
        method: method.get,
        params: {
            start_time,
            end_time,
            search
        }
    })
}


//#endregion