// 导航菜单配置
const asideMenuConfig = [{
    name: '订单管理',
    path: '/order',
    icon: 'icon-test3',
    power: [0, 1]
  }, {
    name: '订单到期提醒',
    path: '/remind',
    icon: 'icon-test3',
    power: [0]
  },
  {
    name: '商户管理',
    path: '/merchants',
    icon: 'ziyingyewu',
    children: [{
      name: '邀请码管理',
      path: "/merchants/invitecode"
    }, {
      name: '折扣设置',
      path: '/merchants/discount'
    }],
    power: [0]
  },
  {
    name: '投放规则设置',
    path: '/rule',
    icon: 'toufangguize-copy',
    children: [],
    power: [0]
  },
  {
    name: '广告机管理',
    path: '/ad-machine',
    icon: 'mobile',
    power: [0]
  },
  {
    name: '小程序设置',
    path: '/mini',
    icon: 'mobile-phone',
    children: [{
      name: "轮播图设置",
      path: "/mini/slideshow"
    }, {
      name: "推荐机位设置",
      path: '/mini/recommend'
    }, {
      name: "案例设置",
      path: '/mini/case'
    }],
    power: [0]
  },
  {
    name: '合作伙伴管理',
    path: '/friend',
    icon: 'supplier-features',
    children: [],
    power: [0]
  },
  {
    name: '加盟申请审核',
    path: '/audit',
    icon: 'rejected-order',
    children: [],
    power: [0]
  },
  {
    name: '数据统计',
    path: '/statistical',
    icon: 'icon-test',
    children: [{
      name: "投放数据统计",
      path: '/statistical/data'
    }, {
      name: "财务统计",
      path: '/statistical/money'
    }],
    power: [0]
  },
]

export {
  asideMenuConfig
}