import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import '@alifd/next/reset.scss';
//引入主题色
import '@alifd/theme-14191/index.scss'
import router from './config/router'
import './commons/common.scss'
//设置日期中文显示
import 'moment/locale/zh-cn'

ReactDOM.render(
  router,
  document.getElementById('ice-container')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();