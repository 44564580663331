
import React from 'react';
import { Overlay, Form, Input, Message, Select, NumberPicker } from '@alifd/theme-14191';
import './index.scss'

import OverlayTitle from '../../Common/OverlayTitle'
import OverlayFooter from '../../Common/OverlayFooter'
import { getMainContainer } from '../../Common/common';

//apid
import { doLauch } from '../../../config/axios/apis'

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { fixedSpan: 5 }
};

class EditRule extends React.Component {

    constructor() {
        super()
        this.state = {
            discountList: [],
            container: '',
            qrcode: "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg",//二维码
            info: {},
            add: true,
        }
    }

    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let info = "";
        let add = true;
        if (nextProps.editRule && nextProps.ruleModel) {
            info = JSON.parse(JSON.stringify(nextProps.ruleModel))
            add = false;
        }
        this.setState({
            info,
            add
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var info = this.state.info;
        if (!info) info = {};
        info[key] = value
        this.setState({
            info
        })
    }

    //保存
    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        var act = 'add';
        var id = '';
        if (!this.state.add) {
            //修改
            act = "edit"
            id = this.state.info.id
        }
        const { number, givnumber, price } = values;
        // 新建投放规则之后报错
        doLauch(act, id, number, givnumber, price).then((res) => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        }).catch((ex) => {
            Message.error('请检查输入的信息！是否有误！');
        })
    }

    render() {
        const { editRule, closeDialog } = this.props;
        const { container, info, add, discountList } = this.state;
        return (
            <Overlay title="新增用户"
                visible={editRule}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >

                <Form className="edit-people">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? "添加套餐" : "编辑套餐"} closeDialog={closeDialog} />
                    {/* 内容 */}
                    <div className="content">
                        <FormItem label="套餐次数" {...formItemLayout} required requiredMessage="必填">
                            <NumberPicker style={{ width: '100%' }} name="number" onChange={(value) => this.changeInfo(value, 'number')} value={info ? info.number : ''} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="赠送次数" {...formItemLayout} required requiredMessage="必填">
                            <NumberPicker style={{ width: '100%' }} name="givnumber" onChange={(value) => this.changeInfo(value, 'givnumber')} value={info ? info.givnumber : ''} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="价格" {...formItemLayout} required requiredMessage="必填">
                            <NumberPicker style={{ width: '100%' }} name="price" onChange={(value) => this.changeInfo(value, 'price')} value={info ? info.price : ''} precision={2} placeholder="请输入" />
                        </FormItem>
                    </div>

                    <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                </Form>
            </Overlay>
        )
    }
}

export default EditRule