import React, { Fragment } from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid, Dialog } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import './index.scss'

import EditDiscount from "./EditDiscount";
import { getDiscountList } from '../../../config/axios/apis';
import moment from 'moment';
import { exportXlsx } from '../../../config/export';
import HeaderTitle from '../../../components/HeaderTitle';

class Fix extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            discountShow: false,//新增编辑折扣
            discountModel: undefined,
            //查询信息
            count_page: 0,
            page: 1,
            limit: 15,
        }
    }

    //刷新数据
    refresh = () => {
        var tempAll = [];
        const { page, limit } = this.state;
        getDiscountList(page, limit).then((res) => {
            if (res.data) {
                res.data.forEach((item, index) => {
                    tempAll.push({
                        ruleId: item.id,
                        ruleDiscount: item.discount,
                        item
                    });
                })
                this.setState({
                    dataSource: tempAll,
                    count_page: res.count_page
                })
            }
        })
    }

    export = () => {
        var search = this.state.searchInfo, receive_staff_id = this.state.receive_staff_id, start_time = this.state.start_time, end_time = this.state.end_time;

        // window.open(`http://modoka.dysense.cn/admin/Materiel/getRepairList?receive_staff_id=${receive_staff_id}&search=${search}&start_time=${start_time}&end_time=${end_time}&excel=1`)
        exportXlsx(`/admin/Materiel/getRepairList?receive_staff_id=${receive_staff_id}&search=${search}&start_time=${start_time}&end_time=${end_time}&excel=1`, '维修管理');
    }

    componentDidMount() {
        this.refresh();

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    editPeople = (record) => {

    }

    //打开审批
    openEditDiscount = (record) => {
        this.setState({
            discountShow: true,
            discountModel: record
        })
    }
    //关闭弹窗
    closeDialog = () => {
        this.setState({
            discountShow: false
        })
        this.refresh();
    }

    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => this.openEditDiscount(record)}>修改折扣</Button>
            </div >
        )
    }

    render() {
        const { dataSource, discountShow, searchInfo, page, limit, count_page, peopleList } = this.state;
        return (
            <Fragment>
                <HeaderTitle>折扣列表</HeaderTitle>
                <div className="people">

                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={24}>
                            <Button type="primary" style={{ float: 'right' }} onClick={() => {
                                this.openEditDiscount(null);
                            }} >新增折扣</Button>
                        </Grid.Col>
                    </Grid.Row>

                    {/* 表格 */}
                    <div className="content">
                        <Table hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="规则ID" dataIndex="ruleId" width={110} />
                            <Table.Column title="规则折扣" dataIndex="ruleDiscount" width={110} />
                            <Table.Column title="操作" cell={this.operation} width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>
                    {/* 新增折扣 */}
                    <EditDiscount discountShow={discountShow} closeDialog={this.closeDialog} discountModel={this.state.discountModel} />
                </div>
            </Fragment>
        )
    }
}
export default Fix;

