import React, { Component } from 'react';
//layout 样式
import '@icedesign/layout/build/index.css'
import Layout from '@icedesign/layout';
import Header from './components/Header';
import Aside from './components/Aside';
// import Footer from './components/Footer';
import MainRoutes from './MainRoutes';
import './index.scss';

export default class BasicLayout extends Component {

  componentDidMount(){
    // console.log(this.props)
  }

  render() {
    return (
      <Layout
        fixable
        style={{ minHeight: '100vh' }}
        className="ice-design-layout"
      >
        <Layout.Section>

          <Layout.Header>
            <Header />
          </Layout.Header>

          <Layout.Main >
            <Layout.Aside width={182}>
              <Aside />
            </Layout.Aside>
            <div className="main-container" id="main-container" >
              <MainRoutes />
            </div>
            {/* <Footer /> */}
          </Layout.Main>

        </Layout.Section>
      </Layout>
    );
  }
}
