import React from 'react'
import { Overlay, Form, Icon, Button } from "@alifd/theme-14191";
// import './index.scss'

import OverlayTitle from '../../../Common/OverlayTitle'
import OverlayFooter from '../../../Common/OverlayFooter'
import { getMainContainer } from '../../../Common/common';
import { seeQrcode } from '../../../../config/axios/apis';
import axios from 'axios';

import store from '../../../../config/store'

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};

class SeeQrcode extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            img: '',
            video: '',
            power: -1
        }
    }
    componentDidMount() {
        var power = store.get('login_power');
        this.setState({
            container: getMainContainer(),
            power
        })
    }

    componentWillReceiveProps(nextProps) {
        let model = null;
        let img = '';
        let video = ''
        if (nextProps.editModel && nextProps.editModelInfo) {
            model = nextProps.editModelInfo.item;
            var str = model.resources.substring(model.resources.lastIndexOf('.') + 1);
            let imgType = ["bmp", "png", "gif", "jpg", "jpeg"];
            let videoType = ["mp4"]
            if (imgType.indexOf(str) > -1) {
                img = model.resources.startsWith('https://lk.dysense.cn') ? model.resources : "https://lk.dysense.cn" + model.resources;
            }
            if (videoType.indexOf(str) > -1) {
                video = model.resources.startsWith('https://lk.dysense.cn') ? model.resources : "https://lk.dysense.cn" + model.resources;
            }
        }
        this.setState({
            model,
            img,
            video
        })
    }

    handleSubmit = () => {
        var { img, video } = this.state;
        var mouse = new MouseEvent('click')
        var a = document.createElement('a')
        if (img || video) {
            axios({
                url: img ? img : video,
                responseType: 'blob'
            }).then((res) => {
                var hz = img.substring(img.lastIndexOf('.'));
                a.download = this.state.model.id + hz;
                a.href = window.URL.createObjectURL(res.data);
                a.dispatchEvent(mouse)
                this.props.closeDialog();
            })
        }
    }

    render() {
        const { editModel, closeDialog } = this.props;
        const { container, video, img, power } = this.state;
        return (
            <Overlay title="型号管理"
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title="查看素材" closeDialog={() => {
                        this.setState({
                            img: '',
                            video: ''
                        })
                        closeDialog()
                    }} />
                    <div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
                        {img ? <img style={{ width: '80%' }} src={img ? img : ""} /> : null}
                        {video ? <video style={{ width: '80%' }} controls autoPlay src={video ? video : ''} /> : null}

                    </div>

                    <div className='btns' style={{
                        textAlign: 'center',
                        margin: '20px auto 20px',
                    }}>
                        {
                            power == 0 ? <Button type='primary' onClick={() => {
                                this.setState({
                                    img: '',
                                    video: ''
                                })
                                this.handleSubmit();
                            }}><Icon type="download" />
                                下载素材</Button> : null
                        }

                    </div>
                </div>
            </Overlay>
        )
    }
}

export default SeeQrcode;
