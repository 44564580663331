import React from 'react'
import { Overlay, Form, Input } from "@alifd/theme-14191";
import './index.scss'

import OverlayTitle from '../../../../Common/OverlayTitle'
import OverlayFooter from '../../../../Common/OverlayFooter'
import { getMainContainer } from '../../../../Common/common';

//apis
import { doModel } from '../../../../../config/axios/apis'

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};

class EditModel extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            add: false
        }
    }
    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let model = null;
        let add = true;
        if (nextProps.editModel && nextProps.editModelInfo) {
            model = JSON.parse(JSON.stringify(nextProps.editModelInfo));
            add = false;
        }
        this.setState({
            model,
            add
        })
    }


    //改变信息
    changeInfo = (value, key) => {
        var model = this.state.model;
        if (!model) model = {};
        model[key] = value;
        this.setState({
            model: model
        })
    }

    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        let act = 'add';
        let id = ''
        if (!this.state.add) {
            act = 'edit';
            id = this.state.model.id
        }
        // console.log(values, errors, act, id)
        doModel(act, id, values.model).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        })
    }

    render() {
        const { editModel, closeDialog } = this.props;
        const { container, model, add } = this.state;
        return (
            <Overlay title="型号管理"
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
            // onOpen={() => {
            //     if (container) {
            //         container.style.overflow = "hidden";
            //     }
            // }}
            // beforeClose={() => {
            //     if (container) {
            //         container.style.overflow = "";
            //     }
            // }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? '新增型号' : '编辑型号'} closeDialog={closeDialog} />
                    <Form className="form-model-add">
                        <Form.Item size="large" label="型号" {...formItemLayout} required requiredMessage="必填">
                            <Input name="model" onChange={(value) => { this.changeInfo(value, 'model') }} value={model ? model.model : ''} placeholder="请输入" />
                        </Form.Item>
                        <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                    </Form>

                </div>
            </Overlay>
        )
    }
}

export default EditModel;
