import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker, Input } from '@alifd/theme-14191'
import moment from 'moment'
import "./index.scss"

import { getMainContainer } from '../Common/common'

import { getKarolList, karoldScreen } from '../../config/axios/apis'
import { exportXlsx } from '../../config/export'
import HeaderTitle from '../../components/HeaderTitle'

import EditMachine from './components/EditMachine'

let statusCss = ['audit', 'put'];
let status = ["空闲", "投放中"]

class AdMachine extends React.Component {

  constructor() {
    super();
    this.state = {
      dataSource: [],
      statusList: [{
        id: '全部',
        name: "全部"
      }],//订单状态
      ruleList: [{
        id: '全部',
        name: "全部"
      }],//投放规则
      selectList: [],
      shopItem: null,
      regionItem: null,
      editMachine: false,//编辑广告机
      machineModel: undefined,
      //查询信息
      count_page: 0,
      shop: null, region: null, layer: null,
      page: 1,
      limit: 15
    }
  }

  //刷新查询数据
  refresh = () => {
    const { page, limit, shop, region, layer } = this.state;
    getKarolList(page, limit, shop, region, layer).then((res) => {
      var tempAll = [];
      res.data.forEach((item, index) => {
        // console.log(item)
        tempAll.push({
          id: item.id,
          shop: item.shop,
          area: item.region,
          layer: item.layer,
          number: item.karol_number,
          time: item.order_scheduling ? moment(item.order_scheduling * 1000).format("YYYY-MM-DD HH:mm:ss") : '',
          remark: item.remark,
          status: item.order_scheduling ? 1 : 0,
          item,
        })
      })
      this.setState({
        dataSource: tempAll,
        count_page: res.count_page,
      })
      // this.forceUpdate();
    })
    this.getSelect();
  }

  //获取广告机筛选数据
  getSelect = () => {
    karoldScreen().then(res => {
      this.setState({
        selectList: res.data
      })
    })
  }

  //删除咖啡机
  delCoffee = (id) => {
    Dialog.confirm({
      title: '提示',
      content: '确定删除吗？',
      messageProps: {
        type: 'error'
      },
      onOk: () => {
        doCoffee('del', id).then(() => {
          this.refresh();
        })
        // doModel('del', id, '').then(() => {
        //     this.refresh();
        // })
      },
    });
  }

  //导出
  export = () => {
    var belonger_id = this.state.belonger_id;
    var search = this.state.searchInfo;
    // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
    exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
  }

  componentDidMount() {
    this.refresh();

    this.setState({
      // dataSource: tempAll,
      container: getMainContainer()
    })
  }


  //点击新增或编辑
  editMachine = (machineModel) => {
    this.state.container.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.setState({
      editMachine: true,
      machineModel: machineModel
    })
  }
  //关闭Dialog
  closeDialog = () => {
    this.refresh();
    this.setState({
      editMachine: false,
      modelShow: false,
      seeAssetsShow: false
    })
  }

  //投放状态
  statusText = (value, index, record) => {
    return <div className={statusCss[record.status]}>{status[record.status]}</div>
  }
  //操作
  operation = (value, index, record) => {
    return (
      <div className="operation" style={{ color: '#9F7558' }}>
        <Button text onClick={() => {
          this.editMachine(record)
        }}>修改</Button>
      </div >
    )
  }


  render() {
    const { dataSource, orderModel, searchInfo, ruleList, page, count_page, limit, selectList, shopItem, regionItem, shop, region, layer } = this.state;

    return (
      <Fragment>
        <HeaderTitle>广告机列表</HeaderTitle>
        <div className="order">
          {/* 头部 */}
          <Grid.Row className="header">
            <Grid.Col span={4}>
              <Grid.Row className="search-item">
                <Grid.Col span={5}>
                  <span className="search-label">商场</span>
                </Grid.Col>
                <Grid.Col span={19}>
                  <Select value={shop} style={{ width: '100%' }} onChange={(v) => {
                    var shopItem = selectList.find(m => m.name == v);
                    this.setState({
                      shop: v,
                      shopItem,
                      regionItem: null,
                      region: null,
                      layer: null
                    })

                  }}>
                    <Select.Option value=''>请选择</Select.Option>
                    {
                      selectList.map((item, index) => {
                        return (
                          <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                        )
                      })
                    }
                  </Select>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col span={1}></Grid.Col>
            <Grid.Col span={4}>
              <Grid.Row className="search-item">
                <Grid.Col span={5}>
                  <span className="search-label">区域</span>
                </Grid.Col>
                <Grid.Col span={19}>
                  <Select value={region} style={{ width: '100%' }} onChange={(v) => {
                    var regionItem = shopItem.children.find(m => m.name == v);
                    this.setState({
                      region: v,
                      regionItem,
                      layer: null
                    })
                  }}>
                    <Select.Option value=''>请选择</Select.Option>
                    {
                      shopItem ?
                        shopItem.children.map((item, index) => {
                          return (
                            <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                          )
                        }) : null
                    }
                  </Select>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col span={1}></Grid.Col>
            <Grid.Col span={4}>
              <Grid.Row className="search-item">
                <Grid.Col span={5}>
                  <span className="search-label">层数</span>
                </Grid.Col>
                <Grid.Col span={19}>
                  <Select value={layer} style={{ width: '100%' }} onChange={(v) => {
                    this.setState({
                      layer: v
                    })
                  }}>
                    <Select.Option value=''>请选择</Select.Option>
                    {
                      regionItem ?
                        regionItem.children.map((item, index) => {
                          return (
                            <Select.Option value={item} key={index}>{item}</Select.Option>
                          )
                        }) : null
                    }
                  </Select>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col span={1}></Grid.Col>
            <Grid.Col span={5}>
              <Grid.Row>
                <Grid.Col span={2} />
                <Grid.Col span={6}>
                  <Button type="primary" onClick={() => {
                    this.setState({
                      page: 1
                    }, this.refresh)
                  }}>搜索</Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col span={4}>
              <Button type='primary' style={{ float: 'right' }} onClick={() => {
                this.editMachine(null)
              }}>新增</Button>
            </Grid.Col>
          </Grid.Row>
          {/* 表格 */}
          <div className="content">
            <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
              <Table.Column title="商场" dataIndex="shop" width={110} />
              <Table.Column title="区域" dataIndex="area" width={150} />
              <Table.Column title="层数" dataIndex="layer" width={150} />
              <Table.Column title="编号" dataIndex="number" width={150} />
              <Table.Column title="订单排期" dataIndex="time" width={160} />
              <Table.Column title="备注" dataIndex="remark" width={150} />
              <Table.Column title="投放状态" dataIndex="status" width={150} cell={this.statusText} />
              <Table.Column title="操作" cell={this.operation} width={250} />
            </Table>
            <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
              this.setState({
                page: current
              }, () => {
                this.refresh()
              })
            }} />
          </div>
        </div>
        <EditMachine editMachine={this.state.editMachine} closeDialog={this.closeDialog} machineModel={this.state.machineModel} />
      </Fragment>
    )
  }
}
export default AdMachine;

