import React from 'react'
import { Overlay, Form, Input, Upload, Icon, Button, Message } from "@alifd/theme-14191";
// import './index.scss'

import OverlayTitle from '../../../Common/OverlayTitle'
import OverlayFooter from '../../../Common/OverlayFooter'
import { getMainContainer } from '../../../Common/common';
//apis
import { doBanner } from '../../../../config/axios/apis';

var baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = "/api";
}

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};

class EditInfo extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            add: false,
            img: []
        }
    }
    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let model = null;
        let add = true;

        let img = []
        if (nextProps.editModel && nextProps.editModelInfo) {
            model = JSON.parse(JSON.stringify(nextProps.editModelInfo));
            add = false;
            img.push({
                name: 'IMG',
                state: 'done',
                size: 1024,
                downloadURL: model.img,
                fileURL: model.img,
                imgURL: model.img
            })
        }
        this.setState({
            model,
            add, img
        })
    }

    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        if (!this.state.img || this.state.img.length <= 0) {
            Message.error("请上传图片")
            return;
        }
        let act = "add";
        let id = '';
        if (!this.state.add) {
            act = 'edit'
            id = this.state.model.id;
        }
        var img = this.state.img[0].imgURL;
        doBanner(act, id, img).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var model = this.state.model;
        if (!model) model = {};
        model[key] = value;
        this.setState({
            model: model
        })
    }


    onUploadSuccess = (file) => {
        file.imgURL = 'https://lk.dysense.cn' + file.response.file_url
        var img = [file]
        this.setState({
            img
        })
    }

    render() {
        const { editModel, closeDialog } = this.props;
        const { container, model, add, img } = this.state;
        return (
            <Overlay
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? '新增轮播图' : "修改轮播图"} closeDialog={closeDialog} />
                    <Form className="form-model-add">
                        <Form.Item required requiredMessage="必填">
                            <Upload.Dragger
                                style={{
                                    width: '100%'
                                }}
                                listType="image"
                                action={baseUrl + '/admin/Caseli/upload_file'}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
                                onSuccess={this.onUploadSuccess}
                                value={img}
                            >
                                <div className="next-upload-drag" style={{ padding: '50px' }}>
                                    <p className="next-upload-drag-icon"><Icon style={{
                                        color: '#FF8A65'
                                    }} type="upload" /></p>
                                    <p className="next-upload-drag-text"></p>
                                    <p className="next-upload-drag-hint"> </p>
                                </div>
                            </Upload.Dragger>
                            <div style={{
                                'margin-top': '10px',
                                'font-size': '14px',
                                'font-family': 'PingFangSC-Regular, PingFang SC',
                                'font-weight': 400,
                                'color': '#666666',
                                'line-height': '28px',
                            }}>只支持JPG/PNG/GIF，GIF文件不能动画化，大小不超过5M，建议尺寸为
                                686X290</div>
                        </Form.Item>
                        {/* <Form.Item size="large" label="成本价" {...formItemLayout} required requiredMessage="必填">
                            <Input name="price" onChange={(value) => { this.changeInfo(value, 'price') }} value={model ? model.price : ''} placeholder="请输入" />
                        </Form.Item> */}
                        <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                    </Form>

                </div>
            </Overlay>
        )
    }
}

export default EditInfo;
