import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getRawMaterial } from '../../../config/axios/apis'

import moment from 'moment';
import { exportXlsx } from '../../../config/export';
const otherInfo = ['小杯申领', '小杯客户总消耗', '大杯申领', '大杯客户总消耗', '月销售杯数']
class MaterailDataSum extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: [],
            dataSourceAll: [],
            peopleNum: [],
            //查询信息
            searchInfo: '',
            start_time: '',//开始时间
            end_time: '',//结束时间
        }
    }

    refresh = () => {
        const { start_time, end_time } = this.state;
        getRawMaterial(start_time, end_time).then((res) => {
            // console.log(res)
            var peopleNum = res.data[0];

            var tempAll = [];
            for (var i = 1; i < res.data.length; i++) {
                var model = {};
                res.data[i].forEach((item, index) => {
                    model['people' + index] = item;
                })
                tempAll.push(model)
            }

            this.setState({
                dataSource: tempAll,
                peopleNum
            })

        })
    }

    componentDidMount() {
        this.refresh();

        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var model = {
        //         material: '原物料',
        //     }
        //     this.state.peopleNum.map((item) => {
        //         model['people' + item] = '员工' + item
        //     })
        //     model = {
        //         ...model,
        //         sum: '合计',
        //         price: '成本',
        //         singleprice: '单杯成本',
        //     }
        //     tempAll.push(model);
        // }
        // otherInfo.map((item) => {
        //     var model = {
        //         material: item,//'原物料',
        //     }
        //     this.state.peopleNum.map((item) => {
        //         model['people' + item] = '员工' + item
        //     })
        //     model = {
        //         ...model,
        //         sum: '合计',
        //         price: '成本',
        //         singleprice: '单杯成本',
        //     }
        //     tempAll.push(model);
        // })

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }


    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/Raw_material_analysis_summary?start_time${start_time}=&end_time=${end_time}&excel=1`);
        exportXlsx(`/admin/Operate/Raw_material_analysis_summary?start_time=${start_time}&end_time=${end_time}&excel=1`, '原物料分析汇总')
    }

    render() {
        const { dataSource, dataSourceAll, peopleNum } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    {/* <Grid.Col span={5}> */}
                    {/* <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" /> */}
                    {/* </Grid.Col> */}
                    {/* <Grid.Col span={1}></Grid.Col> */}
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={this.refresh}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={13}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export} >导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        {/* <Table.Column title="原物料" dataIndex="material" width={120} /> */}
                        {
                            peopleNum.map((item, index) => {
                                return (
                                    <Table.Column title={item} dataIndex={'people' + index} width={110} />
                                )
                            })
                        }
                        {/* <Table.Column title="合计" dataIndex="sum" width={110} />
                        <Table.Column title="成本" dataIndex="price" width={110} />
                        <Table.Column title="单杯成本" dataIndex="singleprice" width={110} /> */}
                    </Table>
                </div>

            </div>
        )
    }
}
export default MaterailDataSum;

