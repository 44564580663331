import React from 'react'
import { Overlay, Form, Input, Upload, Icon, Button, Select, Message } from "@alifd/theme-14191";
// import './index.scss'

import OverlayTitle from '../../../Common/OverlayTitle'
import OverlayFooter from '../../../Common/OverlayFooter'
import { getMainContainer } from '../../../Common/common';
//apis
import { doKarolRecommend, getKarolList } from '../../../../config/axios/apis';

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};

var baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = "/api";
}

class EditInfo extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            add: false,
            img: [],
            list: []
        }
    }

    getList = () => {
        getKarolList(1, 1, '', '', '', 0).then((res) => {
            var list = [];
            res.data.forEach((item) => {
                list.push({
                    id: item.id,
                    name: `${item.shop} ${item.region} ${item.layer} ${item.karol_number}`
                })
            })
            this.setState({
                list
            })
        })
    }

    componentDidMount() {
        this.getList();
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let model = null;
        let add = true;
        let img = [];
        if (nextProps.editModel && nextProps.editModelInfo) {
            model = JSON.parse(JSON.stringify(nextProps.editModelInfo));
            add = false;
            img.push({
                name: 'IMG',
                state: 'done',
                size: 1024,
                downloadURL: model.img,
                fileURL: model.img,
                imgURL: model.img
            })
        }
        this.setState({
            model,
            add,
            img
        })
    }

    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        let act = "add";
        let id = '';
        if (!this.state.img || this.state.img.length <= 0) {
            Message.error("请上传案例素材")
            return;
        }
        if (!this.state.add) {
            act = 'edit'
            id = this.state.model.id;
        }
        var img = this.state.img[0].imgURL;
        const { k_id, shop, desc, } = values;
        doKarolRecommend(act, id, k_id, shop, desc, img).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var model = this.state.model;
        if (!model) model = {};
        model[key] = value;
        this.setState({
            model: model
        })
    }


    onUploadSuccess = (file) => {
        file.imgURL = 'https://lk.dysense.cn' + file.response.file_url
        var img = [file]
        this.setState({
            img
        })
    }


    render() {
        const { editModel, closeDialog } = this.props;
        const { container, model, add, img, list } = this.state;
        return (
            <Overlay
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? '新增轮播图' : "推荐机位修改"} closeDialog={closeDialog} />
                    <Form className="form-model-add">
                        <Form.Item label="机位名称" {...formItemLayout} required requiredMessage="必填">
                            <Select name="k_id" onChange={(value) => this.changeInfo(value, 'k_id')} style={{ width: "100%" }} placeholder='请选择机位' value={model ? model.k_id : ''}>
                                {
                                    list.map(item => {
                                        return <Select.Option value={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                            {/* <Input name="name" onChange={(value) => { this.changeInfo(value, 'name') }} value={model ? model.name : ''} placeholder="请输入" /> */}
                        </Form.Item>
                        <Form.Item label="投放商家" {...formItemLayout} required requiredMessage="必填">
                            <Input name="shop" onChange={(value) => { this.changeInfo(value, 'shop') }} value={model ? model.shop : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="商家介绍" {...formItemLayout} required requiredMessage="必填">
                            <Input.TextArea name="desc" onChange={(value) => { this.changeInfo(value, 'desc') }} value={model ? model.desc : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="广告机图片" {...formItemLayout} required requiredMessage="必填">
                            <Upload.Dragger
                                listType="image"
                                action={baseUrl + '/admin/Caseli/upload_file'}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp, video/mp4"
                                onSuccess={this.onUploadSuccess}
                                value={img}
                            >
                                <div className="next-upload-drag">
                                    <p className="next-upload-drag-icon"><Icon type="upload" /></p>
                                    <p className="next-upload-drag-text"></p>
                                    <p className="next-upload-drag-hint"></p>
                                </div>
                            </Upload.Dragger>
                        </Form.Item>
                        <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                    </Form>

                </div>
            </Overlay>
        )
    }
}

export default EditInfo;
