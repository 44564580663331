
import React from 'react';
import { Overlay, Form, Input, Message, Select, NumberPicker } from '@alifd/theme-14191';
import './index.scss'

import OverlayTitle from '../../../../Common/OverlayTitle'
import OverlayFooter from '../../../../Common/OverlayFooter'
import { getMainContainer } from '../../../../Common/common';

//apid
import { getDiscountList, createCode } from '../../../../../config/axios/apis'

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { fixedSpan: 5 }
};

class EditPeople extends React.Component {

    constructor() {
        super()
        this.state = {
            discountList: [],
            container: '',
            qrcode: "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg",//二维码
            info: {},
            add: true,
        }
    }

    getDisList = () => {
        getDiscountList(1, 1, 0).then((res) => {
            var list = [];
            res.data.forEach(item => {
                list.push({
                    id: item.id,
                    discount: item.discount
                })
            })
            this.setState({
                discountList: list
            })
        })
    }


    componentDidMount() {
        this.getDisList();
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let info = "";
        let add = true;
        if (nextProps.editInviteCode && nextProps.inviteCodeModel) {
            info = JSON.parse(JSON.stringify(nextProps.inviteCodeModel))
            add = false;
        }
        this.setState({
            info,
            add
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var info = this.state.info;
        if (!info) info = {};
        info[key] = value
        this.setState({
            info
        })
    }

    //保存
    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        const { ruleId, number } = values;
        createCode(ruleId, number).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        })
    }

    render() {
        const { editInviteCode, closeDialog } = this.props;
        const { container, info, add, discountList } = this.state;
        return (
            <Overlay title="新增用户"
                visible={editInviteCode}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >

                <Form className="edit-people">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? "生成邀请码" : "编辑用户"} closeDialog={closeDialog} />
                    {/* 内容 */}
                    <div className="content">
                        <FormItem label="折扣规则" {...formItemLayout} required requiredMessage="必填">
                            <Select name="ruleId" onChange={(value) => this.changeInfo(value, 'ruleId')} style={{ width: "100%" }} placeholder='请选择折扣规则'>
                                {
                                    discountList.map(item => {
                                        return <Select.Option value={item.id}>{item.discount}</Select.Option>
                                    })
                                }
                            </Select>
                        </FormItem>

                        <FormItem label="生成数量" {...formItemLayout} required requiredMessage="必填">
                            <NumberPicker style={{ width: '100%' }} name="number" onChange={(value) => this.changeInfo(value, 'number')} placeholder="请输入" />
                        </FormItem>
                    </div>

                    <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                </Form>
            </Overlay>
        )
    }
}

export default EditPeople