import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker, Input, NumberPicker } from '@alifd/theme-14191'
import moment from 'moment'
import EditRule from './EditRule'
import "./index.scss"

import { getMainContainer } from '../Common/common'

import { getLauchList, doLauch } from '../../config/axios/apis'
import { exportXlsx } from '../../config/export'
import HeaderTitle from '../../components/HeaderTitle'

class Rule extends React.Component {

  constructor() {
    super();
    this.state = {
      dataSource: [],
      // 包月
      monthValue: [{
        name: '1月',
        value: '',
      }, {
        name: '2月',
        value: '',
      }, {
        name: '3月',
        value: '',
      }, {
        name: '4月',
        value: '',
      }, {
        name: '5月',
        value: '',
      }, {
        name: '半年',
        value: '',
      }, {
        name: '一年',
        value: '',
      }],
      statusList: [{
        id: '全部',
        name: "全部"
      }],//订单状态
      ruleList: [{
        id: '全部',
        name: "全部"
      }],//投放规则
      modelShow: false,//型号管理
      // 编辑规则
      editRule: false,
      ruleModel: undefined,
      //查询信息
      count_page: 0,
      belonger_id: '',
      searchInfo: '',
      page: 1,
      limit: 15
    }
  }

  //刷新查询数据
  refresh = () => {
    var monthValue = this.state.monthValue;
    const { belonger_id, searchInfo, page, limit } = this.state;
    const fun = (res) => {
      var tempAll = [];
      res.data.forEach((item, index) => {
        // console.log(item)
        if (item.launch_type == 2) {
          tempAll.push({
            id: item.id,
            number: item.frequency,
            givnumber: item.give_frequency,
            price: item.amount,
            item,
          })
        } else {
          var t = monthValue.find(m => m.name == item.month);
          if (t) {
            t.value = item.amount;
            t['item'] = item;
          }
        }
      })
      this.setState({
        dataSource: tempAll,
        count_page: res.count_page,
      })
    }
    getLauchList('', page, limit).then((res) => {
      fun(res)
    })
  }

  //删除咖啡机
  delRule = (id) => {
    Dialog.confirm({
      title: '提示',
      content: '确定删除吗？',
      messageProps: {
        type: 'error'
      },
      onOk: () => {
        doLauch('del', id).then(() => {
          this.refresh();
        })
      },
    });
  }

  //导出
  export = () => {
    var belonger_id = this.state.belonger_id;
    var search = this.state.searchInfo;
    // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
    exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
  }

  componentDidMount() {
    this.refresh();

    this.setState({
      // dataSource: tempAll,
      container: getMainContainer()
    })
  }


  //点击新增或编辑
  editRule = (ruleModel) => {
    this.state.container.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.setState({
      editRule: true,
      ruleModel: ruleModel
    })
  }
  //关闭Dialog
  closeDialog = () => {
    this.refresh();
    this.setState({
      editRule: false,
      modelShow: false,
      seeAssetsShow: false
    })
  }

  //操作
  operation = (value, index, record) => {
    return (
      <div className="operation" style={{ color: '#9F7558' }}>
        <Button text onClick={() => {
          this.editRule(record)
        }}>修改</Button>
        <Button text onClick={() => {
          this.delRule(record.id)
        }}>删除</Button>
      </div >
    )
  }


  render() {
    const { dataSource, ruleModel, searchInfo, ruleList, page, count_page, limit, monthValue } = this.state;

    return (
      <Fragment>
        <HeaderTitle>包月</HeaderTitle>
        <div className="order">
          {/* 头部 */}
          <Grid.Row className="header">
            {
              monthValue.map(item => {
                return <Grid.Col span={3}>
                  <Grid.Row className="search-item">
                    <Grid.Col span={7}>
                      <span className="search-label">{item.name}</span>
                    </Grid.Col>
                    <Grid.Col span={15}>
                      <NumberPicker precision={2} placeholder='请输入' onChange={(v) => {
                        doLauch('edit', item.item.id, 0, 0, v).then((res) => {

                        })
                        item.value = v;
                        this.forceUpdate();
                      }} value={item.value} />
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              })
            }

          </Grid.Row>
        </div>
        <div style={{
          height: '1px',
          width: '100%',
          margin: '30px 0',
          background: '#DCDEE3'
        }}></div>
        <HeaderTitle>按次数</HeaderTitle>
        <div className="order">
          {/* 头部 */}
          <Grid.Row className="header">
            <Grid.Col span={24}>
              <Button type="primary" style={{ float: 'right' }} onClick={() => {
                this.editRule(null);
              }} >新增套餐</Button>
            </Grid.Col>
          </Grid.Row>
          {/* 表格 */}
          <div className="content">
            <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
              <Table.Column title="套餐次数" dataIndex="number" width={110} />
              <Table.Column title="赠送次数" dataIndex="givnumber" width={150} />
              <Table.Column title="套餐价格" dataIndex="price" width={150} />
              <Table.Column title="操作" cell={this.operation} width={250} />
            </Table>
            <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
              this.setState({
                page: current
              }, () => {
                this.refresh()
              })
            }} />
          </div>
          {/* 新增修改 */}
          <EditRule editRule={this.state.editRule} closeDialog={this.closeDialog} ruleModel={this.state.ruleModel} />
        </div>
      </Fragment>
    )
  }
}
export default Rule;

