import marker from '../../static/images/marker.png'

let container = null
export const getMainContainer = () => {
    if (!container) {
        container = document.getElementsByClassName('main-container')[0];
    }
    return container
}

//生成随机数
export const RandomNum = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
}
//生成随机数
export const RandomNumFloat = (min, max) => {
    return parseFloat(Math.random() * (max - min) + min);
}

let haveColorBase = [];
let haveColor = [];
var min = 0;
var max = 180;
//获取随机图片
export const getMarker = async (num) => {
    var count = num - haveColorBase.length;

    const createImg = async () => {
        const handle = () => {
            var r = RandomNum(min, max)
            var g = RandomNum(min, max);
            var b = RandomNum(min, max)
            while (haveColor.indexOf(r + '|' + g + '|' + b) > -1) {
                r = RandomNum(min, max)
                g = RandomNum(min, max);
                b = RandomNum(min, max)
            }
            return new Promise((resolve, reject) => {
                var newCanvas = document.createElement("canvas");
                var image = new Image();
                image.src = marker;
                image.onload = function () {
                    newCanvas.width = image.width;
                    newCanvas.height = image.height;
                    var context = newCanvas.getContext('2d')
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var imageData = context.getImageData(0, 0, newCanvas.width, newCanvas.height);
                    var length = imageData.data.length;
                    for (var index = 0; index < length; index += 4) {
                        //这里可以对 r g b 进行计算（这里的rgb是每个像素块的rgb颜色）
                        imageData.data[index] = r;
                        imageData.data[index + 1] = g;
                        imageData.data[index + 2] = b;
                    }
                    context.putImageData(imageData, 0, 0);
                    resolve(newCanvas.toDataURL('image/png'))
                }
            })
        }
        var data = "";
        await handle().then((res) => {
            data = res;
        });
        return data;
    }

    for (var i = 0; i < count; i++) {
        haveColorBase.push(await createImg());
    }
    return haveColorBase.slice(0, num)
}
