import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker } from '@alifd/theme-14191'
import moment from 'moment'
import "./index.scss"
//添加编辑订单
import EditCoffee from './components/EditCoffee'
//型号管理
import Model from './components/Model'
//查看而为am
import SeeAssets from './components/SeeAssets'
import { getMainContainer } from '../Common/common'

// import { doCoffee, getCoffeeList, getPeopleList } from '../../config/axios/apis'
import { getOrderRemindList, getLauchList, orderVerify } from '../../config/axios/apis'


import { exportXlsx } from '../../config/export'
import HeaderTitle from '../../components/HeaderTitle'

import store from '../../config/store'

const orderStatusCss = ["nopay", "audit", "faild", "review", "put", "complate"];
const orderStatus = ["未支付", "审核中", "审核失败", "重新审核中", "投放中", "已完成"];

class Order extends React.Component {

    constructor() {
        super();
        this.state = {
            dataSource: [],
            statusList: [{
                id: '全部',
                name: "全部"
            }],//订单状态
            ruleList: [{
                id: '全部',
                name: "全部"
            }],//投放规则
            modelShow: false,//型号管理
            editCoffee: false,//新增编辑咖啡机
            orderModel: undefined,//正在编辑的
            seeAssetsShow: false,
            //查询信息
            count_page: 0,
            launch_id: '',
            order_status: '',
            pay_time: '',
            page: 1,
            limit: 15,
            power: -1,
        }
    }

    //刷新查询数据
    refresh = () => {
        const { page, limit, launch_id, order_status, pay_time } = this.state;
        getOrderRemindList(page, limit, {
            launch_id, order_status, pay_time
        }).then((res) => {
            console.log(res)
            var tempAll = [];
            res.data.forEach((item, index) => {
                var machine = [];
                item.karol_list.forEach(gl => {
                    machine.push(`${gl.shop} ${gl.region} ${gl.layer} ${gl.karol_number}`)
                })
                tempAll.push({
                    number: item.order_number,
                    time: moment(item.order_time * 1000).format("YYYY-MM-DD HH:mm:ss"),
                    paytime: item.pay_time,
                    money: item.order_money,
                    machine,
                    rule: item.launch_type == 2 ? "按次数" : "按月",
                    status: item.order_status,//'状态'
                    day: item.day,
                    num: item.frequency_num,
                    item,
                })
            })
            this.setState({
                dataSource: tempAll,
                count_page: res.count_page,
            })
            this.forceUpdate();
        })
    }

    // 获取所有的投放规则
    getToufangList = () => {
        getLauchList('', 1, 1, 0).then((res) => {
            var ruleList = [];
            res.data.forEach(item => {
                ruleList.push({
                    id: item.id,
                    name: item.launch_type == 1 ? item.month : `${item.count_frequency}次（含赠送${item.give_frequency}次） ${item.amount}`,
                });
            })
            this.setState({
                ruleList
            })
        })
    }

    //删除咖啡机
    delCoffee = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doCoffee('del', id).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //导出
    export = () => {
        var belonger_id = this.state.belonger_id;
        var search = this.state.searchInfo;
        // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
        exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
    }

    componentDidMount() {
        this.refresh();
        this.getToufangList();
        var power = store.get('login_power');

        this.setState({
            container: getMainContainer(),
            power
        })

    }

    changeOrderStatus = (msg, id, status) => {
        Dialog.confirm({
            title: '提示',
            content: msg,
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                orderVerify(id, status).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //关闭Dialog
    closeDialog = () => {
        this.refresh();
        this.setState({
            editCoffee: false,
            modelShow: false,
            seeAssetsShow: false
        })
    }

    //订单状态
    orderStatus = (value, index, record) => {
        return <>
            <div className={orderStatusCss[record.status]}>{orderStatus[record.status]}</div>
        </>
    }

    //投放机器
    getMachine = (value, index, record) => {
        return <>
            {
                record.machine.map(item => {
                    return <div>{item}</div>
                })
            }
        </>
    }
    //查看素材
    seeAssets = (value, index, record) => {
        this.setState({
            seeAssetsShow: true,
            orderModel: record
        })
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.seeAssets(value, index, record);
                }}>查看素材</Button>
                <Button text onClick={() => {
                    this.changeOrderStatus("确定通过审核吗？", record.item.id, 4);
                }}>通过审核</Button>
                <Button text onClick={() => {
                    this.changeOrderStatus("确定驳回审核吗？", record.item.id, 2);
                }}>驳回审核</Button>
            </div >
        )
    }


    render() {
        const { dataSource, orderModel, searchInfo, ruleList, page, count_page, limit, statusList, power } = this.state;

        return (
            <Fragment>
                <HeaderTitle>订单列表</HeaderTitle>
                <div className="order">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={5}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={8}>
                                    <span className="search-label">订单状态</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            order_status: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            orderStatus.map((item, index) => {
                                                return (
                                                    <Select.Option value={index} key={index}>{item}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={5}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={8}>
                                    <span className="search-label">投放规则</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            launch_id: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            ruleList.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={5}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={8}>
                                    <span className="search-label">支付日期</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <DatePicker onChange={(v) => {
                                        var pay_time = '';
                                        if (v) {
                                            pay_time = v._d.getTime() / 1000
                                        }
                                        this.setState({
                                            pay_time,
                                        })
                                    }} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={5}>
                            <Grid.Row>
                                <Grid.Col span={2} />
                                <Grid.Col span={6}>
                                    <Button type="primary" onClick={() => {
                                        this.setState({
                                            page: 1
                                        }, this.refresh)
                                    }}>搜索</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            {/* <Button type='primary' style={{ float: 'right' }}>新增</Button> */}
                        </Grid.Col>
                    </Grid.Row>

                    {/* 表格 */}
                    <div className="content">
                        <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="订单编号" dataIndex="number" width={170} />
                            <Table.Column title="下单时间" dataIndex="time" width={160} />
                            <Table.Column title="支付时间" dataIndex="paytime" width={150} />
                            {
                                power == 0 ? <Table.Column title="订单金额" dataIndex="money" width={110} /> : null
                            }

                            <Table.Column title="投放机位" dataIndex="machine" width={150} cell={this.getMachine} />
                            <Table.Column title="投放规则" dataIndex="rule" width={110} />
                            <Table.Column title="订单状态" dataIndex="status" width={110} cell={this.orderStatus} />
                            <Table.Column title="剩余天数" dataIndex="day" width={110} />
                            <Table.Column title="剩余次数" dataIndex="num" width={110} />
                            {/* <Table.Column title="操作" cell={this.operation} width={250} /> */}
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>
                    {/* 型号管理 */}
                    {/* <Model modelShow={this.state.modelShow} closeDialog={this.closeDialog} /> */}
                    {/* 新增修改 */}
                    {/* <EditCoffee editCoffee={this.state.editCoffee} closeDialog={this.closeDialog} orderModel={orderModel} /> */}
                    {/* 查看二维码 */}
                    <SeeAssets editModel={this.state.seeAssetsShow} closeDialog={this.closeDialog} editModelInfo={orderModel} />
                </div >
            </Fragment>
        )
    }
}
export default Order;

