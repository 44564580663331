import axios from "axios"

var baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = "/api";
}
var loading = null;
const exportXlsx = (url, filename) => {
    if (!loading) {
        loading = document.getElementById('loading');
    }
    loading.style.display = "block";
    axios({
        url: baseUrl + url,
        responseType: 'arraybuffer'
    }).then((res) => {
        loading.style.display = "none";
        let blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }); // 将二进制流转为blob
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob); // 创建新的url并指向file对象或blob对象的地址
        a.href = url;
        a.download = filename; // 设置下载文件名
        let body = document.getElementsByTagName('body')[0];
        body.appendChild(a);
        a.click();
        body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(url); // 释放内存
    })
}

export {
    exportXlsx
}