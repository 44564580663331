
import React from 'react';
import { Overlay, Form, Input, Message, Select } from '@alifd/theme-14191';
// import './index.scss'

import OverlayTitle from '../../../Common/OverlayTitle'
import OverlayFooter from '../../../Common/OverlayFooter'
import { getMainContainer } from '../../../Common/common';

//apid
import { doKarol } from '../../../../config/axios/apis'

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { fixedSpan: 5 }
};

class EditMachine extends React.Component {

    constructor() {
        super()
        this.state = {
            materials: [""],//物料
            container: '',
            qrcode: "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg",//二维码
            info: {},
            add: true,
        }
    }

    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let info = "";
        let add = true;
        if (nextProps.editMachine && nextProps.machineModel) {
            info = nextProps.machineModel;
            add = false;
        }
        this.setState({
            info,
            add
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var info = this.state.info;
        if (!info) info = {};
        info[key] = value
        this.setState({
            info
        })
    }

    //保存
    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        var act = 'add';
        var id = '';
        if (!this.state.add) {
            //修改
            act = "edit"
            id = this.state.info.id
        }
        const { shop, area, layer, number, remark } = values;
        doKarol(act, id, shop, area, layer, number, remark).then((res) => {
            // console.log(res)
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        }).catch((ex) => {
            Message.error('请检查输入的信息！是否有误！');
        })
    }

    render() {
        const { editMachine, closeDialog } = this.props;
        const { container, info, add } = this.state;
        return (
            <Overlay title="新增用户"
                visible={editMachine}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >

                <Form className="edit-people">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? "新增广告机" : "编辑广告机"} closeDialog={closeDialog} />
                    {/* 内容 */}
                    <div className="content">
                        <FormItem label="商场" {...formItemLayout} required requiredMessage="必填">
                            <Input name="shop" onChange={(value) => this.changeInfo(value, 'shop')} value={info ? info.shop : ""} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="区域" {...formItemLayout} required requiredMessage="必填">
                            <Input name="area" onChange={(value) => this.changeInfo(value, 'area')} value={info ? info.area : ""} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="层数" {...formItemLayout} required requiredMessage="必填">
                            <Input name="layer" onChange={(value) => this.changeInfo(value, 'layer')} value={info ? info.layer : ""} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="编号" {...formItemLayout} required requiredMessage="必填">
                            <Input name="number" onChange={(value) => this.changeInfo(value, 'number')} value={info ? info.number : ""} placeholder="请输入" />
                        </FormItem>
                        <FormItem label="备注" {...formItemLayout} required requiredMessage="必填">
                            <Input.TextArea name="remark" onChange={(value) => this.changeInfo(value, 'remark')} value={info ? info.remark : ""} placeholder="请输入" />
                        </FormItem>
                    </div>

                    <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                </Form>
            </Overlay>
        )
    }
}

export default EditMachine