import React from 'react';

function HeaderTitle(props) {
  return (
    <div className='all-title'>
      <div className='line'></div>
      <div className='title-text'>{props.children}</div>
    </div>
  );
}

export default HeaderTitle;
