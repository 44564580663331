import React, { Fragment } from 'react'
import { Button, Icon, Input, Select, Table, Pagination, Dialog, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import './index.scss'

//编辑
import EditInfo from "./EditInfo";

import { getCaseList } from '../../../config/axios/apis';
import HeaderTitle from '../../../components/HeaderTitle';


// const nums = []
class MaterialManager extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            editModel: false,//显示编辑
            editModelInfo: [],//当前编辑信息
            auditShow: false,//审批
            nums: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
        }
    }

    //刷新数据
    refresh = () => {
        var tempAll = [];
        const { searchInfo, page, limit } = this.state;
        getCaseList(page, limit).then((res) => {
            console.log(res.data)
            if (res.data) {
                res.data.forEach((item, i) => {
                    var model = {
                        id: item.id,
                        name: item.case_title,
                        desc: item.case_content,
                        sort: item.sort,
                        pos: item.karol_info,
                        asset: item.resources.startsWith('https://lk.dysense.cn') ? item.resources : "https://lk.dysense.cn" + item.resources,
                        cover_img: item.cover_img ? item.cover_img.startsWith('https://lk.dysense.cn') ? item.cover_img : "https://lk.dysense.cn" + item.cover_img : '',
                        shop_uname: item.shop_uname
                    }
                    tempAll.push(model)
                })
                this.setState({
                    dataSource: tempAll,
                    count_page: res.count_page
                })
            }
        })
    }

    componentDidMount() {
        this.refresh();

        this.setState({
            container: getMainContainer()
        })
    }

    editInfo = (record) => {
        // var temp = undefined;
        // if (record) {
        //     console.log(record)
        // }
        this.setState({
            editModelInfo: record,
            editModel: true
        })
    }

    //关闭
    closeDialog = () => {
        this.refresh();
        this.setState({
            editModelInfo: undefined,
            editModel: false,
            auditShow: false
        })
    }
    //打开审批
    openAudit = () => {
        this.setState({
            auditShow: true
        })
    }

    //查看二维码
    imgShow = (value, index, record) => {
        let img, video;
        var str = record.asset.substring(record.asset.lastIndexOf('.') + 1);
        let imgType = ["bmp", "png", "gif", "jpg", "jpeg"];
        let videoType = ["mp4"]
        if (imgType.indexOf(str) > -1) {
            img = record.asset;
        }
        if (videoType.indexOf(str) > -1) {
            video = record.asset;
        }
        return <>
            {
                img ? <img style={{ width: '50px' }} src={record.asset} /> : <img style={{ width: '50px' }} src="https://lk.dysense.cn/uploads/resource/20211219/c7deb2a2d00b43a0aed0e18725a26edf.jpeg" onClick={() => {
                    Dialog.show({
                        content: <video style={{ width: '100%' }} controls autoPlay src={video}></video>
                    })
                }} />
            }
        </>
    }

    //查看二维码
    imgShow2 = (value, index, record) => {
        var img = record.cover_img
        return <>
            {
                img ? <img style={{ width: '50px' }} src={record.cover_img} /> : null
            }
        </>
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.editInfo(record)
                }}>修改</Button>
            </div >
        )
    }

    render() {
        const { dataSource, editModel, editModelInfo, auditShow, nums, searchInfo, page, limit, count_page } = this.state;
        return (
            <Fragment>
                <HeaderTitle>案例列表</HeaderTitle>
                <div className="people">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={24}>
                            <Button type="primary" style={{ float: 'right' }} onClick={this.editInfo.bind(this, undefined)}>新增</Button>
                        </Grid.Col>
                    </Grid.Row>
                    {/* 表格 */}
                    <div className="content">
                        <Table hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="投放商家" dataIndex="shop_uname" width={110} />
                            <Table.Column title="案例名称" dataIndex="name" width={110} />
                            <Table.Column title="案例介绍" dataIndex="desc" width={110} />
                            <Table.Column title="案例排序" dataIndex="sort" width={110} />
                            <Table.Column title="投放机位" dataIndex="pos" width={110} />
                            <Table.Column title="案例素材" dataIndex="asset" width={110} cell={this.imgShow} />
                            <Table.Column title="封面图" dataIndex="cover_img" width={110} cell={this.imgShow2} />
                            <Table.Column title="操作" cell={this.operation} width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>

                    <EditInfo editModel={editModel} closeDialog={this.closeDialog} editModelInfo={editModelInfo} />
                </div>
            </Fragment>
        )
    }
}
export default MaterialManager;

