import axios from 'axios';
import store from "../store";

var baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = "/api";
}
// const baseUrl = "/api"; //"http://modoka.dysense.cn";

axios.interceptors.request.use((req) => {
    if (req.url.indexOf('/admin/Login/login') < 0) {
        var login_id = store.get('login_id');
        if (!login_id) {
            store.clearAll()
            window.location.href = "/"
        }
    }
    return req
})

/**
 * 请求
 * @param {*} config 参数 包含url，method，data，params
 */
export const Ajax = (config) => {
    return new Promise((resolve, reject) => {
        if (config.url && config.method) {
            axios({
                url: baseUrl + config.url,
                method: config.method,
                data: config.data,
                params: config.params,
            }).then((res) => {
                if (config.url.indexOf('/admin/Machine/qrcode') > -1) {
                    resolve(res)
                } else {
                    if (res.status === 200 && res.data.code === 200) {
                        resolve(res.data)
                    } else {
                        reject(res)
                    }
                }
            }).catch((ex) => {
                reject(ex)
            })
        } else {
            reject('参数无效')
        }
    })
}