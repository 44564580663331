import React from 'react';
import { withRouter } from 'react-router-dom'
import styles from './index.module.scss'

function Body() {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Page Not Found</h1>
      <p className={styles.text}>Sorry, but the page you were trying to view does not exist.</p>
    </div>
  )
}

export default withRouter(Body)