import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getMonthOperateExcel, getMonthOperateList } from '../../../config/axios/apis'
import moment from 'moment';
import { exportXlsx } from '../../../config/export';
class MonthDataSum extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            dataSourceSum: [],
            dataSourceAve: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
            start_time: '',//开始时间
            end_time: '',//结束时间
        }
    }

    refresh = () => {
        const { searchInfo, page, limit, start_time, end_time } = this.state;
        getMonthOperateExcel(searchInfo, start_time, end_time).then((res) => {
            console.log(res)
            var dataSourceSum = [];
            dataSourceSum.push({
                number: '总计',
                department: '',
                people: '',
                glnum: res.data.total.machine_num,//'管理台数',
                jhnum: res.data.total.loading_num,//'加货次数',
                fpnum: res.data.total.invoice_num,//'发票次数',
                wxsendnum: res.data.total.send_repair_num,//'维修订单发送数',
                zwnum: res.data.total.me_repair_num,//'自我修复数',
                zbnum: (res.data.total.repair_rate * 100).toFixed(2) + '%',//'其占比',
                xbnum: res.data.total.small_cup_num,//'小杯销售杯数',
                dbnum: res.data.total.big_cup_num,//'大杯销售杯数',
                allnum: res.data.total.count_cup_num,//'总销售杯数',
                daynum: res.data.total.day_operate_num,//'日运营次数',
                singlenum: res.data.total.single_sale_num,// '单次贩卖杯数',
                singleavenum: res.data.total.single_machine_sale_num,//'单次平均销售杯数',
                db2num: res.data.total.sub_class_num,//'代班次数',
                bdbnum: res.data.total.cover_sub_class_num,//'被代班次数',
            })
            var dataSourceAve = [];
            dataSourceAve.push({
                number: '平均',
                department: '',
                people: '',
                glnum: res.data.average.machine_num,//'管理台数',
                jhnum: res.data.average.loading_num,//'加货次数',
                fpnum: res.data.average.invoice_num,//'发票次数',
                wxsendnum: res.data.average.send_repair_num,//'维修订单发送数',
                zwnum: res.data.average.me_repair_num,//'自我修复数',
                zbnum: (res.data.average.repair_rate * 100).toFixed(2) + '%',//'其占比',
                xbnum: res.data.average.small_cup_num,//'小杯销售杯数',
                dbnum: res.data.average.big_cup_num,//'大杯销售杯数',
                allnum: res.data.average.count_cup_num,//'总销售杯数',
                daynum: res.data.average.day_operate_num,//'日运营次数',
                singlenum: res.data.average.single_sale_num,// '单次贩卖杯数',
                singleavenum: res.data.average.single_machine_sale_num,//'单次平均销售杯数',
                db2num: res.data.average.sub_class_num,//'代班次数',
                bdbnum: res.data.average.cover_sub_class_num,//'被代班次数',
            })
            this.setState({
                dataSourceAve,
                dataSourceSum
            })
        })
        getMonthOperateList(searchInfo, start_time, end_time, page, limit).then((res) => {
            var dataSource = [];
            res.data.forEach((item, index) => {
                dataSource.push({
                    number: index + 1,
                    department: item.branch,
                    people: item.name,
                    glnum: item.machine_num,//'管理台数',
                    jhnum: item.loading_num,//'加货次数',
                    fpnum: item.invoice_num,//'发票次数',
                    wxsendnum: item.send_repair_num,//'维修订单发送数',
                    zwnum: item.me_repair_num,//'自我修复数',
                    zbnum: (item.repair_rate * 100).toFixed(2) + '%',//'其占比',
                    xbnum: item.small_cup_num,//'小杯销售杯数',
                    dbnum: item.big_cup_num,//'大杯销售杯数',
                    allnum: item.count_cup_num,//'总销售杯数',
                    daynum: item.day_operate_num,//'日运营次数',
                    singlenum: item.single_sale_num,// '单次贩卖杯数',
                    singleavenum: item.single_machine_sale_num,//'单次平均销售杯数',
                    db2num: item.sub_class_num,//'代班次数',
                    bdbnum: item.cover_sub_class_num,//'被代班次数',
                })
            })
            this.setState({
                dataSource,
                count_page: res.count_page
            })
        })
    }

    componentDidMount() {
        this.refresh()
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var model = {
        //         number: i,
        //         department: '部门',
        //         people: '员工',
        //         glnum: '管理台数',
        //         jhnum: '加货次数',
        //         fpnum: '发票次数',
        //         wxsendnum: '维修订单发送数',
        //         zwnum: '自我修复数',
        //         zbnum: '其占比',
        //         xbnum: '小杯销售杯数',
        //         dbnum: '大杯销售杯数',
        //         allnum: '总销售杯数',
        //         daynum: '日运营次数',
        //         singlenum: '单次贩卖杯数',
        //         singleavenum: '单次平均销售杯数',
        //         dbnum: '代班次数',
        //         bdbnum: '被代班次数',
        //     }
        //     tempAll.push(model);
        // }

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }


    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/getMonthOperateExcel?search=${searchInfo}&start_time${start_time}=&end_time=${end_time}&excel=1`);
        exportXlsx(`/admin/Operate/getMonthOperateExcel?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}&excel=1`, '运营分析汇总');
    }

    render() {
        const { dataSource, dataSourceSum, dataSourceAve, searchInfo, page, limit, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={() => {
                                    this.setState({
                                        page: 1
                                    }, this.refresh)
                                }}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export}  >导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="管理台数" dataIndex="glnum" width={110} />
                        <Table.Column title="加货次数" dataIndex="jhnum" width={110} />
                        <Table.Column title="发票次数" dataIndex="fpnum" width={110} />
                        {/* <Table.Column title="维修订单发送数" dataIndex="wxsendnum" width={120} />
                        <Table.Column title="自我修复数" dataIndex="zwnum" width={110} />
                        <Table.Column title="其占比" dataIndex="zbnum" width={110} /> */}
                        <Table.Column title="小杯销售杯数" dataIndex="xbnum" width={110} />
                        <Table.Column title="大杯销售杯数" dataIndex="dbnum" width={110} />
                        <Table.Column title="总销售杯数" dataIndex="allnum" width={110} />
                        <Table.Column title="日运营次数" dataIndex="daynum" width={110} />
                        <Table.Column title="单次贩卖杯数" dataIndex="singlenum" width={110} />
                        <Table.Column title="单次平均销售杯数" dataIndex="singleavenum" width={130} />
                        <Table.Column title="代班次数" dataIndex="db2num" width={110} />
                        <Table.Column title="被代班次数" dataIndex="bdbnum" width={110} />
                    </Table>
                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                </div>
                {/* 总数 */}
                <div className="content">
                    <Table dataSource={dataSourceSum} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="管理台数" dataIndex="glnum" width={110} />
                        <Table.Column title="加货次数" dataIndex="jhnum" width={110} />
                        <Table.Column title="发票次数" dataIndex="fpnum" width={110} />
                        {/* <Table.Column title="维修订单发送数" dataIndex="wxsendnum" width={120} />
                        <Table.Column title="自我修复数" dataIndex="zwnum" width={110} />
                        <Table.Column title="其占比" dataIndex="zbnum" width={110} /> */}
                        <Table.Column title="小杯销售杯数" dataIndex="xbnum" width={110} />
                        <Table.Column title="大杯销售杯数" dataIndex="dbnum" width={110} />
                        <Table.Column title="总销售杯数" dataIndex="allnum" width={110} />
                        <Table.Column title="日运营次数" dataIndex="daynum" width={110} />
                        <Table.Column title="单次贩卖杯数" dataIndex="singlenum" width={110} />
                        <Table.Column title="单次平均销售杯数" dataIndex="singleavenum" width={130} />
                        <Table.Column title="代班次数" dataIndex="db2num" width={110} />
                        <Table.Column title="被代班次数" dataIndex="bdbnum" width={110} />
                    </Table>
                </div>

                {/* 平均数 */}
                <div className="content">
                    <Table dataSource={dataSourceAve} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="管理台数" dataIndex="glnum" width={110} />
                        <Table.Column title="加货次数" dataIndex="jhnum" width={110} />
                        <Table.Column title="发票次数" dataIndex="fpnum" width={110} />
                        {/* <Table.Column title="维修订单发送数" dataIndex="wxsendnum" width={120} />
                        <Table.Column title="自我修复数" dataIndex="zwnum" width={110} />
                        <Table.Column title="其占比" dataIndex="zbnum" width={110} /> */}
                        <Table.Column title="小杯销售杯数" dataIndex="xbnum" width={110} />
                        <Table.Column title="大杯销售杯数" dataIndex="dbnum" width={110} />
                        <Table.Column title="总销售杯数" dataIndex="allnum" width={110} />
                        <Table.Column title="日运营次数" dataIndex="daynum" width={110} />
                        <Table.Column title="单次贩卖杯数" dataIndex="singlenum" width={110} />
                        <Table.Column title="单次平均销售杯数" dataIndex="singleavenum" width={130} />
                        <Table.Column title="代班次数" dataIndex="db2num" width={110} />
                        <Table.Column title="被代班次数" dataIndex="bdbnum" width={110} />
                    </Table>
                </div>

            </div>
        )
    }
}
export default MonthDataSum;

