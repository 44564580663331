// 路由配置

//登录页面
import Login from '../../pages/UserLogin';
//订单管理
import Order from '../../pages/Order'
import Remind from '../../pages/Remind'
//商户管理
import InviteCode from '../../pages/Merchants/InviteCode'
import Discount from '../../pages/Merchants/Discount'
//投放规则设置
import Rule from '../../pages/Rule'
//广告机管理
import AdMachine from '../../pages/AdMachine';
//小程序设置
import Slideshow from '../../pages/Mini/Slideshow'
import Recommend from '../../pages/Mini/Recommend'
import Case from '../../pages/Mini/Case'
//合作伙伴管理
import Friend from '../../pages/Friend'
//加盟申请审核
import Audit from '../../pages/Audit'

//数据统计
import Data from '../../pages/Statistical/Data'
import Money from '../../pages/Statistical/Money'
import FixSum from '../../pages/Statistical/FixSum'
import ModelData from '../../pages/Statistical/ModelData'
import MonthDataSum from '../../pages/Statistical/MonthDataSum'
import MonthFixSum from '../../pages/Statistical/MonthFixSum'
import PeopleSum from '../../pages/Statistical/PeopleSum'
import PeopleMaterailSum from '../../pages/Statistical/PeopleMaterailSum'
import MaterailDataSum from '../../pages/Statistical/MaterailDataSum'
import ModelYearSum from '../../pages/Statistical/ModelYearSum'

const routerData = [{
  path: '/user/login',
  component: Login
}, {
  path: "/",
}, {
  path: '/order',
  component: Order
},{
  path: '/remind',
  component: Remind
}, {
  path: "/merchants/invitecode",
  component: InviteCode
}, {
  path: "/merchants/discount",
  component: Discount
}, {
  path: '/rule',
  component: Rule,
}, {
  path: "/ad-machine",
  component: AdMachine
}, {
  path: "/mini/slideshow",
  component: Slideshow
}, {
  path: "/mini/recommend",
  component: Recommend
}, {
  path: '/mini/case',
  component: Case
}, {
  path: '/friend',
  component: Friend
}, {
  path: "/audit",
  component: Audit
}, {
  path: "/statistical/data",
  component: Data
}, {
  path: "/statistical/money",
  component: Money
}, {
  path: "/statistical/modeldata",
  component: ModelData
}, {
  path: "/statistical/monthdatasum",
  component: MonthDataSum
}, {
  path: "/statistical/monthfixsum",
  component: MonthFixSum
}, {
  path: "/statistical/peoplesum",
  component: PeopleSum
}, {
  path: "/statistical/peoplematerialsum",
  component: PeopleMaterailSum
}, {
  path: "/statistical/materialdatasum",
  component: MaterailDataSum
}, {
  path: "/statistical/modelyearsum",
  component: ModelYearSum
}]

export default routerData