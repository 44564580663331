import React from 'react'
import { Button, Icon, Input, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getCustomerCountList } from '../../../config/axios/apis'
import moment from 'moment';
import { exportXlsx } from '../../../config/export';

class PeopleSum extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
            start_time: '',//开始时间
            end_time: '',//结束时间
        }
    }
    refresh = () => {
        const { searchInfo, page, limit, start_time, end_time } = this.state;
        getCustomerCountList(searchInfo, start_time, end_time, page, limit).then((res) => {
            var tempAll = []
            res.data.forEach((item, index) => {
                var model = {
                    number: index + 1,
                    name: item.customer,//'客户名称',
                    area: item.region,//'地区',
                    model: item.model_name,//'机型',
                    modelnum: item.machine_number,//'机器编号',
                    cubtype: item.cup_shape,//'杯型',
                    operationpeople: item.name,//'运营员',//item.region,//
                    price: item.unit_price,//'单价',//item.unit_cup_cost_price,//
                    cubnum: item.count_cup_num,//'杯数',
                    cost: item.unit_cup_cost_price,//'单杯成本',
                    monthcost: item.month_cost,//'月成本（加入机器的总物料成本合计）',
                }
                tempAll.push(model);
            })
            this.setState({
                count_page: res.count_page,
                dataSource: tempAll,
            })
        })
    }

    componentDidMount() {
        this.refresh();
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var model = {
        //         number: i,
        //         name: '客户名称',
        //         area: '地区',
        //         model: '机型',
        //         modelnum: '机器编号',
        //         cubtype: '杯型',
        //         operationpeople: '运营员',
        //         price: '单价',
        //         cubnum: '杯数',
        //         cost: '单杯成本',
        //         monthcost: '月成本（加入机器的总物料成本合计）',
        //     }
        //     tempAll.push(model);
        // }

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/getCustomerCountList?search=${searchInfo}&start_time${start_time}=&end_time=${end_time}&excel=1`);
        exportXlsx(`/admin/Operate/getCustomerCountList?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}&excel=1`, '客户统计')
    }

    render() {
        const { dataSource, searchInfo, page, limit, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={() => {
                                    this.setState({
                                        page: 1
                                    }, this.refresh)
                                }}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export}  >导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="客户名称" dataIndex="name" width={110} />
                        <Table.Column title="地区" dataIndex="area" width={110} />
                        <Table.Column title="机型" dataIndex="model" width={110} />
                        <Table.Column title="机器编号" dataIndex="modelnum" width={110} />
                        <Table.Column title="杯型" dataIndex="cubtype" width={110} />
                        <Table.Column title="运营员" dataIndex="operationpeople" width={110} />
                        <Table.Column title="单价" dataIndex="price" width={110} />
                        <Table.Column title="杯数" dataIndex="cubnum" width={110} />
                        <Table.Column title="单杯成本" dataIndex="cost" width={110} />
                        <Table.Column title="月成本（加入机器的总物料成本合计）" dataIndex="monthcost" width={250} />
                    </Table>
                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                </div>

            </div>
        )
    }
}
export default PeopleSum;

