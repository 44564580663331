import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Message } from '@alifd/next'
import bottom from '../../static/images/login/login.png'
import store from '../../config/store'

import './index.scss'


//请求
import { login } from '../../config/axios/apis'

const FormItem = Form.Item;

class UserLogin extends React.Component {

    constructor() {
        super()
        this.state = {
            info: undefined
        }
    }

    componentDidMount() {
        var login_id = store.get('login_id');
        if (login_id) {
            this.props.history.push('/order')
        }
    }

    submitClick = () => {
        if (!this.state.info) {
            Message.error('请填写必要信息');
            return;
        }
        const { username, password } = this.state.info;
        if (!username) {
            Message.error('请输入账号');
            return;
        }
        if (!password) {
            Message.error('请输入密码');
            return;
        }
        login({
            person_name: username,
            person_pass: password
        }).then((res) => {
            if (res.code == 200) {
                var time = 60 * 60 * 1000;//60 * 60 * 1000;
                store.set('login_id', res.data.id, new Date().getTime() + time)
                // 0 管理员 1合作商
                store.set('login_power', res.data.power, new Date().getTime() + time)
                store.set('login_info', JSON.stringify(res.data), new Date().getTime() + time);
                this.props.history.push('/order')
            } else {
                Message.error('账号或密码错误');
            }
        }).catch((ex) => {
            console.log(ex);
            Message.error('账号或密码错误');
        })
    }

    formChange = (info) => {
        this.setState({
            info: info
        })
    }

    render() {
        return (
            <div className="login">
                <img style={{
                    width: "100%",
                    position: "absolute",
                    bottom: "0",
                }} src={bottom} />
                <div className='panel'>
                    <div className='theme-color title'>领客广告位投放管理后台</div>
                    <Form className="account-form" onChange={this.formChange}>
                        <FormItem className="item" required requiredMessage="请输入账号">
                            <Input name="username" placeholder="请输入账号" trim aria-required="true" />
                        </FormItem>
                        <FormItem className="item" required requiredMessage="请输入密码">
                            <Input.Password name="password" placeholder="请输入密码" trim aria-required="true" showToggle={false} />
                        </FormItem>
                        <FormItem className="login">
                            <Form.Submit className="btn" type="primary" onClick={this.submitClick}>
                                登录
                            </Form.Submit>
                        </FormItem>
                    </Form>
                </div>
            </div>
        )
    }

}

export default withRouter(UserLogin)
