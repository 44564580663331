import React from 'react'
import { Button, Overlay, Table, Pagination, Icon, Dialog } from "@alifd/theme-14191";
import './index.scss'

//通用标题
import OverlayTitle from '../../../Common/OverlayTitle'
//添加型号
import EditModel from '../EditModel'

import { getMainContainer } from "../../../Common/common";

//apis
import { doModel, getModelList } from '../../../../config/axios/apis'

class Model extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            editModelShow: false,
            editModelInfo: undefined,
            //apis
            count_page: 0,
            page: 1,
            limit: 15
        }
    }

    //刷新列表
    refresh = () => {
        const { page, limit } = this.state;
        getModelList(page, limit).then((res) => {
            var dataSource = [];
            res.data.forEach((item, index) => {
                dataSource.push({
                    id: item.id,
                    number: index + 1,
                    model: item.model_name,//"机型：" + i
                })
            })
            this.setState({
                dataSource,
                count_page: res.count_page
            })
        })
    }
    //删除型号
    deleteModel = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doModel('del', id, '').then(() => {
                    this.refresh();
                })
            },
        });
    }

    //关闭添加修改
    closeEditModal = () => {
        this.refresh();
        this.setState({
            editModelShow: false,
            editModelInfo: undefined
        })
    }
    openEditModal = (record) => {
        this.setState({
            editModelShow: true,
            editModelInfo: record
        })
    }


    componentWillReceiveProps(props) {
        if (props.modelShow) {
            this.refresh();
        }
    }

    componentDidMount() {
        this.refresh()
        // var dataSource = [];
        // for (var i = 0; i < 10; i++) {
        //     dataSource.push({
        //         number: i,
        //         model: "机型：" + i
        //     })
        // }
        this.setState({
            container: getMainContainer(),
            // dataSource
        })
    }

    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Icon style={{ cursor: 'pointer' }} type="edit" size="xs" onClick={this.openEditModal.bind(this, record)} />
                <Icon style={{ cursor: 'pointer', marginLeft: '30px' }} type="ashbin" size="xs" onClick={this.deleteModel.bind(this, record.id)} />
            </div >
        )
    }

    render() {
        const { modelShow, closeDialog } = this.props;
        const { container, dataSource, editModelShow, editModelInfo, page, count_page, limit } = this.state;
        return (
            <Overlay title="型号管理"
                visible={modelShow}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="model-list">
                    {/* 顶部 */}
                    <OverlayTitle title="型号管理" closeDialog={closeDialog} />
                    <div className="add" style={{ margin: "0 24px" }}>
                        <Button size="large" type="primary" onClick={this.openEditModal.bind(this, undefined)}>新增</Button>
                    </div>
                    <Table style={{ margin: "24px 24px" }} dataSource={dataSource} useVirtual>
                        <Table.Column title="序号" dataIndex="number"></Table.Column>
                        <Table.Column title="型号" dataIndex="model"></Table.Column>
                        <Table.Column title="操作" cell={this.operation}></Table.Column>
                    </Table>

                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                    <EditModel editModel={editModelShow} closeDialog={this.closeEditModal} editModelInfo={editModelInfo} />
                </div>
            </Overlay>
        )
    }
}

export default Model;
