import { HashRouter, Switch, Route } from 'react-router-dom'

//引用最外层布局
import UserLayout from '../../layouts/UserLayout'
import BasicLayout from '../../layouts/BasicLayout';

const router = () => {
    return (
        <HashRouter>
            <Switch>
                <Route path='/user' component={UserLayout} />
                <Route path="/" component={BasicLayout} />
            </Switch>
        </HashRouter>
    )
}

export default router();
