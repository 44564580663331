import React from 'react'
import { Overlay, Form, Input, Upload, Icon, Button, Select, Message } from "@alifd/theme-14191";
// import './index.scss'

import OverlayTitle from '../../../Common/OverlayTitle'
import OverlayFooter from '../../../Common/OverlayFooter'
import { getMainContainer } from '../../../Common/common';
//apis
import { doCase, uploadFile } from '../../../../config/axios/apis';

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};


var baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = "/api";
}

class EditInfo extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            add: false,
            img: [],
            cover_img: []
        }
    }
    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    componentWillReceiveProps(nextProps) {
        let model = null;
        let add = true;
        let img = [];
        let cover_img = []
        if (nextProps.editModel && nextProps.editModelInfo) {
            model = JSON.parse(JSON.stringify(nextProps.editModelInfo));
            add = false;
            img.push({
                name: 'IMG',
                state: 'done',
                size: 1024,
                downloadURL: model.asset,
                fileURL: model.asset,
                imgURL: model.asset
            })
            if (model.cover_img)
                cover_img.push({
                    name: 'cover',
                    state: 'done',
                    size: 1024,
                    downloadURL: model.cover_img,
                    fileURL: model.cover_img,
                    imgURL: model.cover_img
                })
        }
        this.setState({
            model,
            add,
            img,
            cover_img
        })
    }

    handleSubmit = (values, errors) => {
        if (errors) {
            return;
        }
        if (!this.state.img || this.state.img.length <= 0) {
            Message.error("请上传案例素材")
            return;
        }
        let act = "add";
        let id = '';
        if (!this.state.add) {
            act = 'edit'
            id = this.state.model.id;
        }
        var img = this.state.img[0].imgURL;
        var cover_img = this.state.cover_img[0].imgURL;
        const { name, desc, pos, sort, shop_uname } = values;
        doCase(act, id, name, desc, sort, pos, img, shop_uname, cover_img).then(() => {
            if (this.props.closeDialog) {
                this.props.closeDialog();
            }
        })
    }

    //改变信息
    changeInfo = (value, key) => {
        var model = this.state.model;
        if (!model) model = {};
        model[key] = value;
        this.setState({
            model: model
        })
    }



    onUploadSuccess = (file) => {
        file.imgURL = 'https://lk.dysense.cn' + file.response.file_url
        var img = [file]
        this.setState({
            img
        })
    }

    onUploadSuccess2 = (file) => {
        file.imgURL = 'https://lk.dysense.cn' + file.response.file_url
        var cover_img = [file]
        this.setState({
            cover_img
        })
    }


    render() {
        const { editModel, closeDialog } = this.props;
        const { container, model, add, img, cover_img } = this.state;
        return (
            <Overlay
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title={add ? '新增案例' : "修改案例"} closeDialog={closeDialog} />
                    <Form className="form-model-add">
                        <Form.Item label="投放商家" {...formItemLayout} required requiredMessage="必填">
                            <Input name="shop_uname" onChange={(value) => { this.changeInfo(value, 'shop_uname') }} value={model ? model.shop_uname : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="案例名称" {...formItemLayout} required requiredMessage="必填">
                            <Input name="name" onChange={(value) => { this.changeInfo(value, 'name') }} value={model ? model.name : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="案例介绍" {...formItemLayout} required requiredMessage="必填">
                            <Input.TextArea name="desc" onChange={(value) => { this.changeInfo(value, 'desc') }} value={model ? model.desc : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="案例排序" {...formItemLayout} required requiredMessage="必填">
                            <Input name="sort" onChange={(value) => { this.changeInfo(value, 'sort') }} value={model ? model.sort : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="投放机位" {...formItemLayout} required requiredMessage="必填">
                            <Input name="pos" onChange={(value) => { this.changeInfo(value, 'pos') }} value={model ? model.pos : ''} placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="案例素材" {...formItemLayout} required requiredMessage="必填">
                            <Upload.Dragger
                                listType="image"
                                action={baseUrl + '/admin/Caseli/upload_file'}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp,video/mp4"
                                onSuccess={this.onUploadSuccess}
                                value={img}
                            >
                                <div className="next-upload-drag">
                                    <p className="next-upload-drag-icon"><Icon type="upload" /></p>
                                    <p className="next-upload-drag-text"></p>
                                    <p className="next-upload-drag-hint"></p>
                                </div>
                            </Upload.Dragger>
                        </Form.Item>
                        <Form.Item label="封面图" {...formItemLayout} required requiredMessage="必填">
                            <Upload.Dragger
                                listType="image"
                                action={baseUrl + '/admin/Caseli/upload_file'}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
                                onSuccess={this.onUploadSuccess2}
                                value={cover_img}
                            >
                                <div className="next-upload-drag">
                                    <p className="next-upload-drag-icon"><Icon type="upload" /></p>
                                    <p className="next-upload-drag-text"></p>
                                    <p className="next-upload-drag-hint"></p>
                                </div>
                            </Upload.Dragger>
                        </Form.Item>
                        <OverlayFooter form={true} closeDialog={closeDialog} save={this.handleSubmit} />
                    </Form>

                </div>
            </Overlay>
        )
    }
}

export default EditInfo;
