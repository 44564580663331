import React from 'react'
import { Button, Overlay, Table, Pagination, Icon } from "@alifd/theme-14191";
import './index.scss'
//通用标题
import OverlayTitle from '../../../../Common/OverlayTitle'
import { getMainContainer } from "../../../../Common/common";

//api
import { getPeopleMachine } from '../../../../../config/axios/apis'

class Model extends React.Component {

    constructor(props) {
        super()
        this.state = {
            dataSource: [],
            info: null
        }
    }

    refresh = (id) => {
        getPeopleMachine(id).then((res) => {
            var dataSource = [];
            res.data.forEach((item, index) => {
                dataSource.push({
                    number: item.machine_number,
                    customer: item.customer,
                    address: item.address_info,
                    name: item.contacts,
                    phone: item.contact_tel,
                    people: item.name,//"摩哆咖",//归属人
                    materiel: item.materiel_str,
                })
            })
            this.setState({
                dataSource,
            })
        }).catch((ex) => {
            console.error(ex)
        })
    }

    componentWillReceiveProps(nextProps) {
        let info = null;
        if (nextProps.modelShow && nextProps.peopleModel) {
            info = nextProps.peopleModel;
            this.refresh(info.id)
        } else {
            this.setState({
                dataSource: [],
            })
        }
        this.setState({
            info,
        })
    }


    componentDidMount() {
        // var dataSource = [];
        // for (var i = 0; i < 3; i++) {
        //     dataSource.push({
        //         number: i,
        //         customer: "稀客咖啡",
        //         address: "西安市 安阳区",
        //         name: "李小姐",
        //         phone: "11111111",
        //         people: "魔多卡",
        //         materiel: "可可脂 糖精",
        //     })
        // }
        this.setState({
            container: getMainContainer(),
            // dataSource
        })
    }


    render() {
        const { modelShow, closeDialog } = this.props;
        const { container, dataSource } = this.state;
        return (
            <Overlay title="设备管理详情"
                visible={modelShow}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="model-lists">
                    {/* 顶部 */}
                    <OverlayTitle title="设备管理详情" closeDialog={closeDialog} />
                    <Table style={{ margin: "24px 0px" }} dataSource={dataSource} useVirtual>
                        <Table.Column title="机器编号" dataIndex="number"></Table.Column>
                        <Table.Column title="客户" dataIndex="customer"></Table.Column>
                        <Table.Column title="地址" dataIndex="address"></Table.Column>
                        <Table.Column title="联系人" dataIndex="name"></Table.Column>
                        <Table.Column title="联系电话" dataIndex="phone"></Table.Column>
                        <Table.Column title="归属人" dataIndex="people"></Table.Column>
                        <Table.Column title="物料" dataIndex="materiel"></Table.Column>
                    </Table>

                    {/* <Pagination className="page-info" /> */}
                </div>
            </Overlay>
        )
    }
}

export default Model;
