import React from 'react'
import { Button, Form } from "@alifd/theme-14191";
import './index.scss'


class OverlayFooter extends React.Component {

    render() {
        const { closeDialog, form, save, successText } = this.props;
        return (
            <div className="footer">
                {
                    form ? <Form.Submit style={{ marginLeft: "55px" }} type="primary" validate onClick={save}>保存</Form.Submit> : <Button style={{ marginLeft: "55px" }} type="primary" onClick={save}>{successText ? successText : '保存'}</Button>
                }
                <Button style={{ marginLeft: '20px' }} type="secondary" onClick={closeDialog}>取消</Button>
            </div>
        )
    }
}

export default OverlayFooter;
