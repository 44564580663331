import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker, Input } from '@alifd/theme-14191'
import moment from 'moment'
import "./index.scss"
//添加编辑订单
import EditInfo from './components/EditInfo'
//查看而为am
import { getMainContainer } from '../Common/common'

import { getJoinList, joinReturn } from '../../config/axios/apis'
import { exportXlsx } from '../../config/export'
import HeaderTitle from '../../components/HeaderTitle'

class Order extends React.Component {

    constructor() {
        super();
        this.state = {
            dataSource: [],
            statusList: [{
                id: '全部',
                name: "全部"
            }],//订单状态
            ruleList: [{
                id: '全部',
                name: "全部"
            }],//投放规则
            modelShow: false,//型号管理
            editInfo: false,//新增编辑咖啡机
            infoModel: undefined,//正在编辑的
            seeAssetsShow: false,
            //查询信息
            count_page: 0,
            belonger_id: '',
            searchInfo: '',
            page: 1,
            limit: 15
        }
    }

    //刷新查询数据
    refresh = () => {
        const { page, limit } = this.state;
        getJoinList(page, limit).then((res) => {
            var tempAll = [];
            res.data.forEach((item, index) => {
                tempAll.push({
                    id: item.id,
                    name: item.name,
                    phone: item.contact,
                    time: item.apply_time,
                    desc: item.apply_remark,
                    return: item.is_return,
                    item,
                })
            })
            this.setState({
                dataSource: tempAll,
                count_page: res.count_page,
            })
        })

    }

    // 回访
    return = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定回访吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                joinReturn(id).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //删除咖啡机
    delCoffee = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doCoffee('del', id).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //导出
    export = () => {
        var belonger_id = this.state.belonger_id;
        var search = this.state.searchInfo;
        // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
        exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
    }

    componentDidMount() {
        this.refresh();

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }


    //点击新增或编辑
    editInfoHandle = (infoModel) => {
        this.state.container.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            editInfo: true,
            infoModel: infoModel
        })
    }
    //关闭Dialog
    closeDialog = () => {
        this.refresh();
        this.setState({
            editInfo: false,
            modelShow: false,
            seeAssetsShow: false
        })
    }

    //操作
    operation = (value, index, record) => {
        return (
            <>
                {
                    record.return == 0 ?
                        <div className="operation" style={{ color: '#9F7558' }}>
                            <Button text onClick={() => {
                                this.return(record.id)
                            }}>回访</Button>
                        </div > : null
                }
            </>
        )
    }


    render() {
        const { dataSource, infoModel, searchInfo, ruleList, page, count_page, limit, statusList } = this.state;

        return (
            <Fragment>
                <HeaderTitle>加盟申请列表</HeaderTitle>
                <div className="order">
                    {/* 表格 */}
                    <div className="content">
                        <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="姓名" dataIndex="name" width={110} />
                            <Table.Column title="联系方式" dataIndex="phone" width={150} />
                            <Table.Column title="申请时间" dataIndex="time" width={150} />
                            <Table.Column title="申请说明" dataIndex="desc" width={150} />
                            <Table.Column title="操作" cell={this.operation} width={250} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>
                    {/* 新增修改 */}
                    <EditInfo editInfo={this.state.editInfo} closeDialog={this.closeDialog} infoModel={infoModel} />
                </div >
            </Fragment>
        )
    }
}
export default Order;

