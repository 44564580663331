import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getMonthRepairExcel, getMonthRepairList } from '../../../config/axios/apis'
import moment from 'moment';
import { exportXlsx } from '../../../config/export';
class MonthFixSum extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            dataSourceSum: [],
            dataSourceAve: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
            start_time: '',//开始时间
            end_time: '',//结束时间
        }
    }

    refresh = () => {
        const { searchInfo, page, limit, start_time, end_time } = this.state;
        getMonthRepairExcel(searchInfo, start_time, end_time).then((res) => {
            var dataSourceSum = [];
            dataSourceSum.push({
                number: '总计',
                department: '',
                people: '',
                faultnum: res.data.total.fault_num,//'故障问题',
                commonnum: res.data.total.ordinary_num,//'普通问题',
                allnum: res.data.total.count_repair_num,//'总维修次数',
                allhour: res.data.total.count_time_consuming,//'总耗时',
                avehour: res.data.total.average_time_consuming,//'平均耗时',
            })
            var dataSourceAve = [];
            dataSourceAve.push({
                number: '平均',
                department: '',
                people: '',
                faultnum: res.data.average.fault_num,//'故障问题',
                commonnum: res.data.average.ordinary_num,//'普通问题',
                allnum: res.data.average.count_repair_num,//'总维修次数',
                allhour: res.data.average.count_time_consuming,//'总耗时',
                avehour: res.data.average.average_time_consuming,//'平均耗时',
            })
            this.setState({
                dataSourceAve,
                dataSourceSum
            })
        })

        getMonthRepairList(searchInfo, start_time, end_time, page, limit).then((res) => {
            var dataSource = [];
            res.data.forEach((item, index) => {
                dataSource.push({
                    number: index + 1,
                    department: item.branch,
                    people: item.name,

                    faultnum: item.fault_num,//'故障问题',
                    commonnum: item.ordinary_num,//'普通问题',
                    allnum: item.count_repair_num,//'总维修次数',
                    allhour: item.count_time_consuming,//'总耗时',
                    avehour: item.average_time_consuming,//'平均耗时',
                })
            })
            this.setState({
                dataSource,
                count_page: res.count_page
            })
        })
    }


    componentDidMount() {
        this.refresh();
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var model = {
        //         number: i,
        //         department: '部门',
        //         people: '员工',
        //         faultnum: '故障问题',
        //         commonnum: '普通问题',
        //         allnum: '总维修次数',
        //         allhour: '总耗时',
        //         avehour: '平均耗时'
        //     }
        //     tempAll.push(model);
        // }

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/getMonthRepairExcel?search=${searchInfo}&start_time${start_time}=&end_time=${end_time}&excel=1`);
        exportXlsx(`/admin/Operate/getMonthRepairExcel?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}&excel=1`, '维修分析汇总')
    }

    render() {
        const { dataSource, dataSourceSum, dataSourceAve, searchInfo, page, limit, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={() => {
                                    this.setState({
                                        page: 1
                                    }, this.refresh)
                                }}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export}  >导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="故障问题" dataIndex="faultnum" width={110} />
                        <Table.Column title="普通问题" dataIndex="commonnum" width={110} />
                        <Table.Column title="总维修次数" dataIndex="allnum" width={110} />
                        {/* <Table.Column title="总耗时" dataIndex="allhour" width={110} />
                        <Table.Column title="平均耗时" dataIndex="avehour" width={110} /> */}
                    </Table>
                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                </div>
                {/* 总计 */}
                <div className="content">
                    <Table dataSource={dataSourceSum} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="故障问题" dataIndex="faultnum" width={110} />
                        <Table.Column title="普通问题" dataIndex="commonnum" width={110} />
                        <Table.Column title="总维修次数" dataIndex="allnum" width={110} />
                        {/* <Table.Column title="总耗时" dataIndex="allhour" width={110} />
                        <Table.Column title="平均耗时" dataIndex="avehour" width={110} /> */}
                    </Table>
                </div>
                {/* 平均 */}
                <div className="content">
                    <Table dataSource={dataSourceAve} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="部门" dataIndex="department" width={110} />
                        <Table.Column title="员工" dataIndex="people" width={110} />
                        <Table.Column title="故障问题" dataIndex="faultnum" width={110} />
                        <Table.Column title="普通问题" dataIndex="commonnum" width={110} />
                        <Table.Column title="总维修次数" dataIndex="allnum" width={110} />
                        {/* <Table.Column title="总耗时" dataIndex="allhour" width={110} />
                        <Table.Column title="平均耗时" dataIndex="avehour" width={110} /> */}
                    </Table>
                </div>

            </div>
        )
    }
}
export default MonthFixSum;

