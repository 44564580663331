import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker } from '@alifd/theme-14191'
import moment from 'moment'
import "./index.scss"
//添加编辑订单
import EditCoffee from './components/EditCoffee'
//型号管理
import Model from './components/Model'
//查看而为am
import SeeAssets from './components/SeeAssets'
import { getMainContainer } from '../../Common/common'

import { getlaunchStatisticsList, karoldScreen } from '../../../config/axios/apis'
import { exportXlsx } from '../../../config/export'
import HeaderTitle from '../../../components/HeaderTitle'

class Order extends React.Component {

    constructor() {
        super();
        this.state = {
            dataSource: [],
            statusList: [{
                id: '全部',
                name: "全部"
            }],//订单状态
            ruleList: [{
                id: '全部',
                name: "全部"
            }],//投放规则
            modelShow: false,//型号管理
            editCoffee: false,//新增编辑咖啡机
            orderModel: undefined,//正在编辑的
            seeAssetsShow: false,
            totalCount: 0,
            selectList: [],
            shopItem: null,
            regionItem: null,
            editMachine: false,//编辑广告机
            machineModel: undefined,
            //查询信息
            count_page: 0,
            belonger_id: '',
            searchInfo: '',
            page: 1,
            limit: 15
        }
    }

    //获取广告机筛选数据
    getSelect = () => {
        karoldScreen().then(res => {
            this.setState({
                selectList: res.data
            })
        })
    }

    //刷新查询数据
    refresh = () => {
        const { start_time, end_time, shop, region, layer, page, limit } = this.state;
        getlaunchStatisticsList(start_time, end_time, shop, region, layer, page, limit).then((res) => {
            console.log(res)
            var tempAll = [];
            res.data.list.forEach((item, index) => {
                // console.log(item)
                tempAll.push({
                    shop: item.shop,
                    number: item.count_num,
                    area: item.region,
                    layer: item.layer,
                })
            })
            this.setState({
                dataSource: tempAll,
                count_page: res.count_page,
                totalCount: res.data.count_num
            })
            this.forceUpdate();
        })
    }

    //删除咖啡机
    delCoffee = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doCoffee('del', id).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //导出
    export = () => {
        var belonger_id = this.state.belonger_id;
        var search = this.state.searchInfo;
        // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
        exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
    }

    componentDidMount() {
        this.refresh();
        this.getSelect();

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }


    //点击新增或编辑
    editCoffeeHandle = (orderModel) => {
        this.state.container.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            editCoffee: true,
            orderModel: orderModel
        })
    }
    //关闭Dialog
    closeDialog = () => {
        this.refresh();
        this.setState({
            editCoffee: false,
            modelShow: false,
            seeAssetsShow: false
        })
    }

    //查看素材
    seeAssets = (value, index, record) => {
        this.setState({
            seeAssetsShow: true,
            orderModel: record
        })
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.seeAssets(value, index, record);
                }}>查看素材</Button>
                <Button text>通过审核</Button>
                <Button text>驳回审核</Button>
            </div >
        )
    }


    render() {
        const { dataSource, orderModel, searchInfo, ruleList, page, count_page, limit, statusList, selectList, shopItem, regionItem, shop, region, layer, totalCount } = this.state;

        return (
            <Fragment>
                <HeaderTitle>投放数据统计</HeaderTitle>
                <div className="order">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={8}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={4}>
                                    <span className="search-label">投放时间</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <DatePicker.RangePicker defaultVisibleMonth={() => moment().add(-1, 'months')} onChange={(v) => {
                                        var start_time = '';
                                        var end_time = '';
                                        if (v[0]) {
                                            start_time = v[0]._d.getTime() / 1000
                                        }
                                        if (v[1]) {
                                            end_time = v[1]._d.getTime() / 1000
                                        }
                                        this.setState({
                                            start_time,
                                            end_time
                                        })
                                    }} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={6}>
                                    <span className="search-label">商场</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select value={shop} style={{ width: '100%' }} onChange={(v) => {
                                        var shopItem = selectList.find(m => m.name == v);
                                        this.setState({
                                            shop: v,
                                            shopItem,
                                            regionItem: null,
                                            region: null,
                                            layer: null
                                        })

                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            selectList.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={6}>
                                    <span className="search-label">区域</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select value={region} style={{ width: '100%' }} onChange={(v) => {
                                        var regionItem = shopItem.children.find(m => m.name == v);
                                        this.setState({
                                            region: v,
                                            regionItem,
                                            layer: null
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            shopItem ?
                                                shopItem.children.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                                                    )
                                                }) : null
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={6}>
                                    <span className="search-label">层数</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select value={layer} style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            layer: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            regionItem ?
                                                regionItem.children.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item} key={index}>{item}</Select.Option>
                                                    )
                                                }) : null
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={5}>
                            <Grid.Row>
                                <Grid.Col span={2} />
                                <Grid.Col span={6}>
                                    <Button type="primary" onClick={() => {
                                        this.setState({
                                            page: 1
                                        }, this.refresh)
                                    }}>搜索</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                    <div className='all-number' style={{
                        marginTop: '20px'
                    }}>
                        <span style={{
                            "font-size": '14px',
                            "font-family": 'PingFangSC-Regular, PingFang SC',
                            "font-weight": 400,
                            "color": '#666666',
                            "line-height": '22px',
                        }}>总投放次数</span><span style={{
                            "font-size": '30px',
                            "font-family": 'PingFangSC-Semibold, PingFang SC',
                            "font-weight": 600,
                            "color": '#FF8A65',
                            "line-height": '42px',
                            marginLeft: '16px'
                        }}>{totalCount}</span>
                    </div>
                    {/* 表格 */}
                    <div className="content">
                        <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="商场" dataIndex="shop" width={110} />
                            <Table.Column title="区域" dataIndex="area" width={150} />
                            <Table.Column title="层数" dataIndex="layer" width={150} />
                            <Table.Column title="投放次数" dataIndex="number" width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>
                </div >
            </Fragment>
        )
    }
}
export default Order;

