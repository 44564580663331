import React, { Fragment } from 'react'
import { Button, Icon, Input, Table, Pagination, Dialog, Grid, Select, DatePicker } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import EditInviteCode from './components/EditInviteCode'
import Model from './components/Model'
import EditRemark from './components/EditRemark'


//api
// import { getPeopleList, doPeople } from "../../../config/axios/apis";
import { getApplyCodeList, delCode } from "../../../config/axios/apis";
import HeaderTitle from '../../../components/HeaderTitle'
import moment from 'moment'

var codeStatusCss = ['audit', 'complate'];
var codeStatus = ['未使用', '已使用'];

class People extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            statusList: [{
                id: "全部",
                name: "全部"
            }],
            editInviteCode: false,
            inviteCodeModel: undefined,//正在编辑的
            modelShow: false,
            // 修改备注
            editRemark: false,
            remarkModel: undefined,
            //查询信息
            count_page: 0,
            status: '',
            time: '',
            page: 1,
            limit: 15
        }
    }

    //刷新数据
    refresh = () => {
        var tempAll = [];
        const { status, page, limit, time } = this.state;
        getApplyCodeList(page, limit, status, time).then((res) => {
            if (res.data) {
                res.data.forEach((item, index) => {
                    tempAll.push({
                        code: item.code,
                        time: moment(item.code_create_time * 1000).format("YYYY-MM-DD HH:mm:ss"),
                        rule: item.discount,
                        remark: item.remark,
                        status: item.status,
                        item,
                        ruleId: item.discount_id
                    })
                })
                this.setState({
                    dataSource: tempAll,
                    count_page: res.count_page
                })
            }
        })
    }

    componentDidMount() {
        this.refresh()
        this.setState({
            container: getMainContainer()
        })
    }

    // 修改备注
    changeRemark = (record) => {
        this.setState({
            editRemark: true,
            remarkModel: record
        })
    }

    // 删除邀请码
    removeCode = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确认删除邀请码吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                delCode(id).then((res) => {
                    this.refresh();
                })
            },
        });
    }

    //生成邀请码
    editInviteCode = (inviteCodeModel) => {
        this.state.container.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            editInviteCode: true,
            inviteCodeModel: inviteCodeModel
        })
    }
    //关闭Dialog
    closeDialog = () => {
        this.refresh();
        this.setState({
            editInviteCode: false,
            modelShow: false,
            editRemark: false
        })
    }
    //点击
    openModal = (inviteCodeModel) => {
        this.state.container.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            modelShow: true,
            inviteCodeModel: inviteCodeModel
        })
    }

    //页选中点击
    pageChange = (current) => {
        this.setState({
            page: current
        }, () => {
            this.refresh()
        })
    }
    //删除数据
    deletePeople = (record) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doPeople('del', record.id).then(() => {
                    this.refresh();
                })
            },
        });
    }

    //使用状态
    statusText = (value, index, record) => {
        return <div className={codeStatusCss[record.status]}>{codeStatus[record.status]}</div>
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.removeCode(record.item.id)
                }}>删除</Button>
                <Button text onClick={this.changeRemark.bind(this, record)}>修改备注</Button>
            </div >
        )
    }

    render() {
        const { dataSource, inviteCodeModel, searchInfo, page, limit, count_page, statusList } = this.state;
        return (
            <Fragment>
                <HeaderTitle>邀请码管理</HeaderTitle>
                <div className="people">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={4}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={8}>
                                    <span className="search-label">使用状态</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            status: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            codeStatus.map((item, index) => {
                                                return (
                                                    <Select.Option value={index} key={index}>{item}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={4}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={8}>
                                    <span className="search-label">生成日期</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <DatePicker onChange={(v) => {
                                        var time = '';
                                        if (v) {
                                            time = v._d.getTime() / 1000
                                        }
                                        this.setState({
                                            time,
                                        })
                                    }} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={5}>
                            <Grid.Row>
                                <Grid.Col span={2} />
                                <Grid.Col span={6}>
                                    <Button type="primary" onClick={() => {
                                        this.setState({
                                            page: 1
                                        }, this.refresh)
                                    }}>搜索</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <Button type='primary' style={{ float: 'right' }} onClick={() => {
                                this.editInviteCode(null)
                            }}>生成邀请码</Button>
                        </Grid.Col>
                    </Grid.Row>
                    {/* 表格 */}
                    <div className="content">
                        <Table hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="邀请码" dataIndex="code" width={110} />
                            <Table.Column title="生成时间" dataIndex="time" width={110} />
                            <Table.Column title="折扣规则" dataIndex="rule" width={110} />
                            <Table.Column title="备注" dataIndex="remark" width={110} />
                            <Table.Column title="使用状态" dataIndex="status" width={110} cell={this.statusText} />
                            <Table.Column title="操作" cell={this.operation} width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={this.pageChange} />
                    </div>
                    {/* 查看 */}
                    <Model modelShow={this.state.modelShow} closeDialog={this.closeDialog} inviteCodeModel={inviteCodeModel} />
                    {/* 新增修改 */}
                    <EditInviteCode editInviteCode={this.state.editInviteCode} closeDialog={this.closeDialog} inviteCodeModel={inviteCodeModel} />
                    {/* 修改备注 */}
                    <EditRemark editRemark={this.state.editRemark} closeDialog={this.closeDialog} remarkModel={this.state.remarkModel} />
                </div>

            </Fragment>
        )
    }
}
export default People;

