import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getModelCountList } from '../../../config/axios/apis'
import moment from 'moment';
import { exportXlsx } from '../../../config/export';
class ModelData extends React.Component {

    constructor() {
        super()
        this.state = {
            allData: [],
            dataSource: [],
            dataSourceAll: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
            start_time: '',//开始时间
            end_time: '',//结束时间
        }
    }


    refresh = () => {
        const { page, limit, allData } = this.state;
        // allData
        var tempData = [];
        var skipNum = (page - 1) * limit;
        for (var i = skipNum; i < (skipNum + limit); i++) {
            if (i < allData.length) {
                tempData.push(allData[i])
            }
        }

        var temp = [];
        tempData.forEach((item, index) => {
            temp.push({
                number: index + 1,
                model: item.model_name,
                cubtype: item.cup_shape,
                yuebeishu: item.month_cup_num,
                modelnum: item.machine_count,
                pingjunbeishu: item.average_num,
                baoxiucishu: item.repair_num,
                baoxiulv: item.repair_rate + '%'
            })
        })
        this.setState({
            dataSource: temp
        })
    }

    getData = () => {
        const { searchInfo, start_time, end_time } = this.state;
        getModelCountList(searchInfo, start_time, end_time).then((res) => {
            // console.log(res.data)
            var temp = [];
            temp.push({
                number: '总计',
                yuebeishu: res.data.count_month_cup_num,
                modelnum: res.data.count_machine_num,
                pingjunbeishu: res.data.count_average_num,
                baoxiucishu: res.data.count_repair_num,
                baoxiulv: res.data.count_repair_rate + '%'
            })
            this.setState({
                dataSourceAll: temp,
                allData: res.data.list,
                count_page: res.data.list.length
            }, this.refresh)
        })

    }
    componentDidMount() {
        this.getData();
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var model = {
        //         number: i,
        //         model: '机型',
        //         cubtype: '杯型',
        //         yuebeishu: '月杯数',
        //         modelnum: '机器数量',
        //         pingjunbeishu: '平均杯数',
        //         baoxiucishu: '报修次数',
        //         baoxiulv: '单台报修率'
        //     }
        //     tempAll.push(model);
        // }

        // this.setState({
        //     dataSource: tempAll,
        //     container: getMainContainer()
        // })
    }

    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/getModelCountList?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}&excel=1`);
        exportXlsx(`/admin/Operate/getModelCountList?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}&excel=1`, '机型分析')
    }

    render() {
        const { dataSource, dataSourceAll, searchInfo, page, limit, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    {/* <Grid.Col span={1}></Grid.Col> */}
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={() => {
                                    this.setState({
                                        page: 1
                                    }, this.getData)
                                }}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export} >导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        <Table.Column title="序号" dataIndex="number" width={110} />
                        <Table.Column title="机型" dataIndex="model" width={110} />
                        <Table.Column title="杯型" dataIndex="cubtype" width={110} />
                        <Table.Column title="月杯数" dataIndex="yuebeishu" width={110} />
                        <Table.Column title="机器数量" dataIndex="modelnum" width={110} />
                        <Table.Column title="平均杯数" dataIndex="pingjunbeishu" width={110} />
                        <Table.Column title="报修次数" dataIndex="baoxiucishu" width={110} />
                        <Table.Column title="单台报修率" dataIndex="baoxiulv" width={110} />
                    </Table>
                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                </div>
                <div className="content">
                    <Table dataSource={dataSourceAll} useVirtual maxBodyHeight={450}>
                        <Table.Column title="" dataIndex="number" width={110} />
                        <Table.Column title="机型" dataIndex="model" width={110} />
                        <Table.Column title="杯型" dataIndex="cubtype" width={110} />
                        <Table.Column title="月杯数" dataIndex="yuebeishu" width={110} />
                        <Table.Column title="机器数量" dataIndex="modelnum" width={110} />
                        <Table.Column title="平均杯数" dataIndex="pingjunbeishu" width={110} />
                        <Table.Column title="报修次数" dataIndex="baoxiucishu" width={110} />
                        <Table.Column title="单台报修率" dataIndex="baoxiulv" width={110} />
                    </Table>
                </div>

            </div>
        )
    }
}
export default ModelData;

