import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getStaffMaterielCountList, getMaterialsList } from '../../../config/axios/apis'
import moment from 'moment'
import './index.scss'
import { exportXlsx } from '../../../config/export'

// const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
class PeopleMaterailSum extends React.Component {
    constructor() {
        super()
        this.state = {
            allData: [],
            dataSource: [],
            nums: [],
            //查询信息
            searchInfo: '',
            start_time: '',//开始时间
            end_time: '',//结束时间
            page: 1,
            limit: 15,
            count_page: 0
        }
    }

    getModel = (name, remark, data) => {
        var model = {
            people: name,
            remark: remark
        }
        data.forEach((num) => {
            var info = num.split(' ')
            model['material' + info[0]] = info[1]
        })
        return model;
    }

    refresh = (all) => {
        if (all) {
            const { searchInfo, start_time, end_time } = this.state;
            getStaffMaterielCountList(searchInfo, start_time, end_time).then((res) => {
                var tempAll = [];
                // for (var i = 0; i < 15; i++) {
                res.data.forEach((item, index) => {
                    tempAll.push(item)
                })
                // }

                this.setState({
                    allData: tempAll,
                    count_page: tempAll.length
                }, () => {
                    this.refresh()
                })
            })
        } else {
            const { page, limit, allData } = this.state;
            var tempData = [];
            var skipNum = (page - 1) * limit;
            for (var i = skipNum; i < (skipNum + limit); i++) {
                if (i < allData.length) {
                    tempData.push(allData[i])
                }
            }

            var temp = [];
            tempData.forEach((item, index) => {
                // if (item.apply) {
                //     temp.push(this.getModel(item.name, '申领', item.apply));
                // }
                // if (item.retained) {
                //     temp.push(this.getModel(item.name, '留存', item.retained));
                // }
                if (item.use) {
                    temp.push(this.getModel(item.name, '使用', item.use));
                }
            })
            this.setState({
                dataSource: temp
            })
        }
    }

    //获取材料
    getAllMaterials = () => {
        getMaterialsList('', '', '', 2).then((res) => {
            if (res.data) {
                var nums = [];
                res.data.forEach((item) => {
                    nums.push(item.materiel_name);
                })
                this.setState({
                    nums
                })
            }
        })
    }

    componentDidMount() {
        this.getAllMaterials();
        this.refresh(true);
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var num = i % 3;
        //     var model = {
        //         people: '员工' + i,
        //         remark: num === 0 ? '申领' : num === 1 ? "使用" : "留存"
        //     }
        //     nums.map((item) => {
        //         model['material' + item] = '物料' + item
        //     })
        //     model = {
        //         ...model,
        //         sum: '合计',
        //         price: '成本',
        //         singleprice: '单杯成本',
        //     }
        //     tempAll.push(model);
        // }

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    //设置跨行
    getCellProps = (rowIndex, colIndex, dataIndex, record) => {
        // console.log(rowIndex, colIndex, dataIndex, record)
        if (colIndex === 0) {
            // console.log(rowIndex)
            var number = rowIndex % 3;
            if (number === 0) {
                // console.log('跨3行')
                return {
                    colSpan: 1,
                    rowSpan: 3
                };
            }
            if (number === 1) {
                // console.log('跨2行')
                return {
                    colSpan: 1,
                    rowSpan: 2
                };
            }
            if (number === 2) {
                return {
                    colSpan: 1,
                    rowSpan: 1
                };
            }
        }
    };

    export = () => {
        const { searchInfo, start_time, end_time } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/getStaffMaterielCountExcel?search=${searchInfo}&start_time${start_time}=&end_time=${end_time}`);
        exportXlsx(`/admin/Operate/getStaffMaterielCountExcel?search=${searchInfo}&start_time=${start_time}&end_time=${end_time}`,'员工物料库存数据分析汇总')
    }

    render() {
        const { dataSource, searchInfo, nums, page, limit, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col>
                                <DatePicker.RangePicker size="large" showTime={{ defaultValue: [moment('09:00:00', 'HH:mm:ss', true), moment('23:59:59', 'HH:mm:ss', true)], format: 'HH:mm', minuteStep: 15 }} onChange={(v) => {

                                    var start_time = '';
                                    var end_time = '';
                                    if (v[0]) {
                                        start_time = v[0]._d.getTime() / 1000
                                    }
                                    if (v[1]) {
                                        end_time = v[1]._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time,
                                        end_time
                                    })

                                }} />
                            </Grid.Col>
                            {/* <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="开始时间" onChange={(date) => {
                                    var start_time = '';
                                    if (date) {
                                        start_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        start_time
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={1} />
                            <Grid.Col span={11}>
                                <DatePicker style={{ width: '100%' }} className="date-picker" size="large" showTime resetTime placeholder="结束时间" onChange={(date) => {
                                    var end_time = '';
                                    if (date) {
                                        end_time = date._d.getTime() / 1000
                                    }
                                    this.setState({
                                        end_time
                                    })
                                }} />
                            </Grid.Col> */}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Grid.Row>
                            <Grid.Col span={1}></Grid.Col>
                            <Grid.Col span={5}>
                                <Button type="primary" size="large" style={{ float: 'left' }} onClick={this.refresh}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export}>导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    {/* getCellProps={this.getCellProps} */}
                    <Table className="people-table" dataSource={dataSource} useVirtual >
                        <Table.Column title="员工" dataIndex="people" width={120} />
                        <Table.Column title="" dataIndex="remark" width={110} />
                        {
                            nums.map((item, index) => {
                                return (
                                    <Table.Column key={index} title={item} dataIndex={'material' + item} width={110} />
                                )
                            })
                        }
                    </Table>
                    <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                        this.setState({
                            page: current
                        }, () => {
                            this.refresh()
                        })
                    }} />
                </div>

            </div>
        )
    }
}
export default PeopleMaterailSum;

