import React from 'react'
import { Button, Icon, Input, Select, Table, Pagination, DatePicker, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import { getYearOperate } from '../../../config/axios/apis'
import { exportXlsx } from '../../../config/export'

// const otherInfo = ['总计', '实际运营增减（本月-上月）', '平均杯数', '运营员人数', '人均杯数', '人均台数', '总杯数']
class ModelYearSum extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: [],
            //查询信息
            searchInfo: '',
            year: '2021',
            count_page: 0
        }
    }
    refresh = () => {
        const { year, searchInfo } = this.state;
        getYearOperate(searchInfo, year).then((res) => {
            var tempAll = [];
            res.data.forEach((item) => {
                var model = {
                    model: item[0],
                }
                for (var i = 1; i <= 12; i++) {
                    model['month' + i] = item[i]//parseInt(100 * (Math.random()))
                }
                tempAll.push(model);
            })
            this.setState({
                dataSource: tempAll,
                count_page: res.count_page
            })
        })
    }

    componentDidMount() {
        this.refresh();
        // var tempAll = [];
        // for (var i = 0; i < 5; i++) {
        //     var model = {
        //         model: '机型' + i,
        //     }
        //     for (var j = 1; j <= 12; j++) {
        //         model['month' + j] = parseInt(100 * (Math.random()))
        //     }
        //     tempAll.push(model);
        // }
        // for (var item of otherInfo) {
        //     var model = {
        //         model: item,
        //     }
        //     for (var j = 1; j <= 12; j++) {
        //         model['month' + j] = parseInt(100 * (Math.random()))
        //     }
        //     tempAll.push(model);
        // }

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    //获取月份列
    getAllMonth = () => {
        var temp = [];
        for (var i = 1; i <= 12; i++) {
            temp.push(<Table.Column title={i + '月'} dataIndex={'month' + i} width={110} />)
        }
        return temp;
    }

    export = () => {
        const { year, searchInfo } = this.state;
        // window.open(`http://modoka.dysense.cn/admin/Operate/machine_Annual_operation_analysis?search=${searchInfo}&year=${year}&excel=1`);
        exportXlsx(`/admin/Operate/machine_Annual_operation_analysis?search=${searchInfo}&year=${year}&excel=1`, '年度运营统计与分析')
    }

    render() {
        const { dataSource, searchInfo, count_page } = this.state;
        return (
            <div className="people">
                {/* 头部 */}
                <Grid.Row className="header">
                    <Grid.Col span={5}>
                        <Input style={{ width: '100%' }} size="large" placeholder="请输入关键字进行搜索" value={searchInfo} onChange={(value) => {
                            this.setState({
                                searchInfo: value
                            })
                        }} />
                    </Grid.Col>
                    {/* <Grid.Col span={1}></Grid.Col> */}
                    <Grid.Col span={6}>
                        <Grid.Row className="search-item">
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col span={11}>
                                {/* <Input style={{ width: '100%' }} size="large" placeholder="请输入" /> */}
                                <DatePicker.YearPicker style={{ width: '100%' }} className="date-picker" size="large" placeholder="请选择时间" onChange={(res) => {
                                    this.setState({
                                        year: res._d.getFullYear()
                                    })
                                }} />
                            </Grid.Col>
                            <Grid.Col span={4}></Grid.Col>
                            <Grid.Col span={6}>
                                <Button type="primary" size="large" onClick={this.refresh}>查询</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col span={5}>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Button type="secondary" size="large" style={{ float: 'right' }} onClick={this.export}>导出</Button>
                    </Grid.Col>
                </Grid.Row>
                {/* 表格 */}
                <div className="content">
                    <Table dataSource={dataSource} useVirtual maxBodyHeight={450}>
                        <Table.ColumnGroup style={{ borderBottom: 'none' }} title={"上月总台数：" + count_page}>
                            <Table.Column title="机型" dataIndex="model" width={210} />
                            {
                                this.getAllMonth()
                            }
                        </Table.ColumnGroup>
                    </Table>
                </div>

            </div >
        )
    }
}
export default ModelYearSum;

