import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Layout from '@icedesign/layout';
import { Nav } from '@alifd/theme-14191';
import React, { Component } from 'react';
import { asideMenuConfig as menu } from '../../../../config/router/menuConfig'
import './index.scss';
import store from '../../../../config/store'

var asideMenuConfig = [];

const { Item, SubNav } = Nav;
class Aside extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const openKeys = this.getDefaultOpenKeys();
    this.state = {
      openKeys,
      iconOnly: false,
      pathname: location.pathname
    };
  }

  componentDidMount() {
    var power = store.get('login_power');
    asideMenuConfig = menu.filter(m => m.power.indexOf(power) > -1);
    this.props.history.listen(() => {
      power = store.get('login_power');
      asideMenuConfig = menu.filter(m => m.power.indexOf(power) > -1);
      this.forceUpdate()
    })
    this.forceUpdate()
  }

  /**
   * 当前展开的菜单项
   */
  onOpenChange = (openKeys) => {
    this.setState({
      openKeys,
      pathname: ""
    });
  };
  onSelect = (path, b, keyPath) => {
    if (keyPath.keyPath.length > 0) {
      this.setState({
        pathname: path[0]
      })
      return;
    }
    this.setState({
      openKeys: [],
      pathname: path[0]
    })
  }
  // 菜单折叠展开
  oniconOnlyClick = () => {
    this.setState({
      iconOnly: !this.state.iconOnly,
      openKeys: [],
    });
  }
  /**
   * 获取当前展开的菜单项
   */
  getDefaultOpenKeys = () => {
    const { location = {} } = this.props;
    const { pathname } = location;
    let openKeys = [];
    if (pathname === '/') {
      return openKeys;
    }
    if (Array.isArray(asideMenuConfig)) {
      asideMenuConfig.forEach((item, index) => {
        if (index == 2 || index == 5) {
          if (pathname.startsWith(item.path)) {
            openKeys = [`${index}`];
          }
        }
      });
    }
    return openKeys;
  };

  render() {
    let { openKeys, pathname } = this.state;
    return (
      <Layout.Aside width="230" theme="light" className="custom-aside">

        <Nav style={{ width: 230 }} openMode="single"
          className="custom-menu"
          defaultSelectedKeys={[pathname]}
          selectedKeys={[pathname]}
          openKeys={openKeys}
          onOpen={this.onOpenChange}
          onSelect={this.onSelect}
          type="line"
        >
          {
            Array.isArray(asideMenuConfig) &&
            asideMenuConfig.length > 0 &&
            asideMenuConfig.map((nav, index) => {
              if (nav.children && nav.children.length > 0) {
                return (
                  <SubNav
                    key={index}
                    icon={nav.icon ? nav.icon : null}
                    className="yiji"
                    label={
                      <span>
                        <span className="ice-menu-collapse-hide" style={{ marginLeft: '8px' }}>
                          {nav.name}
                        </span>
                      </span>
                    }
                  >
                    {nav.children.map((item) => {
                      const linkProps = {};
                      if (item.newWindow) {
                        linkProps.href = item.path;
                        linkProps.target = '_blank';
                      } else if (item.external) {
                        linkProps.href = item.path;
                      } else {
                        linkProps.to = item.path;
                      }
                      return (
                        <Item key={item.path} icon={item.icon ? item.icon : null} className="erji">
                          <Link {...linkProps}>
                            <span>
                              <span className="ice-menu-collapse-hide" style={{ marginLeft: '8px' }}>{item.name}</span>
                            </span>
                          </Link>
                        </Item>
                      );
                    })}
                  </SubNav>
                );
              }
              const linkProps = {};
              if (nav.newWindow) {
                linkProps.href = nav.path;
                linkProps.target = '_blank';
              } else if (nav.external) {
                linkProps.href = nav.path;
              } else {
                linkProps.to = nav.path;
              }
              return (
                <Item key={nav.path} icon={nav.icon ? nav.icon : null} className="erji">
                  <Link {...linkProps}>
                    <span>
                      <span className="ice-menu-collapse-hide" style={{ marginLeft: '8px' }}>{nav.name}</span>
                    </span>
                  </Link>
                </Item>
              );
            })
          }
        </Nav>

        {/* 侧边菜单项 end */}
      </Layout.Aside>
    );
  }
}

export default withRouter(Aside)

