import React, { Fragment } from 'react'
import { Button, Icon, Input, Select, Table, Pagination, Dialog, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import './index.scss'

//编辑信息
import EditInfo from './EditInfo'
import { doBanner, getBannerList } from '../../../config/axios/apis'
import HeaderTitle from '../../../components/HeaderTitle'

class MaterialInfo extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            editModel: false,//显示编辑
            editModelInfo: undefined,//当前编辑的内容
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15
        }
    }

    //刷新数据
    refresh = () => {
        var tempAll = [];
        const { page, limit } = this.state;
        getBannerList(page, limit).then((res) => {
            if (res.data) {
                res.data.forEach((item, index) => {
                    tempAll.push({
                        id: item.id,
                        number: index + 1,//i,
                        img: item.banner_img.startsWith('https://lk.dysense.cn') ? item.banner_img : "https://lk.dysense.cn" + item.banner_img,//"可可粉：" + i,
                    });
                })
                this.setState({
                    dataSource: tempAll,
                    count_page: res.count_page
                })
            }
        })
    }

    componentDidMount() {
        this.refresh();

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }

    //删除物料
    deleteImg = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doBanner('del', id).then(() => {
                    this.refresh();
                })
            },
        });
    }

    editInfo = (record) => {
        this.setState({
            editModel: true,
            editModelInfo: record
        })
    }
    closeDialog = () => {
        this.refresh();
        this.setState({
            editModel: false,
            editModelInfo: null
        })
    }

    //图片
    imgShow = (value, index, record) => {
        return <img style={{
            width: '50px'
        }} src={record.img} />
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.editInfo(record)
                }}>修改</Button>
                <Button text onClick={() => {
                    this.deleteImg(record.id)
                }}>删除</Button>
            </div >
        )
    }

    render() {
        const { dataSource, editModel, editModelInfo, searchInfo, page, limit, count_page } = this.state;
        return (
            <Fragment>
                <HeaderTitle>轮播图列表</HeaderTitle>
                <div className="people">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={24}>
                            <Button type="primary" style={{ float: 'right' }} onClick={this.editInfo.bind(this, undefined)}>新增</Button>
                        </Grid.Col>
                    </Grid.Row>
                    {/* 表格 */}
                    <div className="content">
                        <Table hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="序号" dataIndex="number" width={110} />
                            <Table.Column title="图片" dataIndex="img" width={110} cell={this.imgShow} />
                            <Table.Column title="操作" cell={this.operation} width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>

                    <EditInfo editModel={editModel} closeDialog={this.closeDialog} editModelInfo={editModelInfo} />
                </div>
            </Fragment>
        )
    }
}
export default MaterialInfo;

