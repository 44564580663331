import React from 'react'
import { Overlay, Form, Icon, Button } from "@alifd/theme-14191";
// import './index.scss'

import OverlayTitle from '../../../../Common/OverlayTitle'
import OverlayFooter from '../../../../Common/OverlayFooter'
import { getMainContainer } from '../../../../Common/common';
import { seeQrcode } from '../../../../../config/axios/apis';

const formItemLayout = {
    labelCol: { fixedSpan: 5 },
    wrapperCol: { span: 16 }
};

class SeeQrcode extends React.Component {

    constructor() {
        super()
        this.state = {
            model: null,
            img: ''
        }
    }
    componentDidMount() {
        this.setState({
            container: getMainContainer(),
        })
    }

    refresh = (id, number) => {
        // var url = 'https://modoka.dysense.cn/scan?id=' + id;
        // seeQrcode(number, url).then((res) => {
        //     this.setState({
        //         img: res.data
        //     })
        // })
    }

    componentWillReceiveProps(nextProps) {
        // let model = null;
        // if (nextProps.editModel && nextProps.editModelInfo) {
        //     model = JSON.parse(JSON.stringify(nextProps.editModelInfo));
        //     // console.log(model)
        //     this.refresh(model.id, model.modelnum)
        // }
        // this.setState({
        //     model
        // })
    }

    //改变信息
    changeInfo = (value, key) => {
        var model = this.state.model;
        if (!model) model = {};
        model[key] = value;
        this.setState({
            model: model
        })
    }

    handleSubmit = () => {
        var mouse = new MouseEvent('click')
        var a = document.createElement('a')
        a.download = this.state.model.modelnum + '.jpg';
        a.href = this.state.img;
        a.dispatchEvent(mouse)
    }

    render() {
        const { editModel, closeDialog } = this.props;
        const { container, model, img } = this.state;
        return (
            <Overlay title="型号管理"
                visible={editModel}
                align="cc cc"
                hasMask
                target="main-container"
                container="main-container"
                onOpen={() => {
                    if (container) {
                        container.style.overflow = "hidden";
                    }
                }}
                beforeClose={() => {
                    if (container) {
                        container.style.overflow = "";
                    }
                }}
            >
                <div className="add-model">
                    {/* 顶部 */}
                    <OverlayTitle title="查看素材" closeDialog={() => {
                        this.setState({
                            img: ''
                        })
                        closeDialog()
                    }} />
                    <div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* img ? img : "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg" */}
                        <img style={{ maxHeight: '320px' }} src={img ? img : "https://img.fengxuan.co/QrCode/permanent/C470EC9E-6183-4D03-BB96-A0210C2A3165/cebfd48a-276f-4c3d-a44e-fdab9a4bbff9.jpg"} />
                    </div>

                    <div className='btns' style={{
                        textAlign: 'center',
                        margin: '0px auto 20px',
                    }}>
                        <Button type='primary' onClick={() => {
                            this.setState({
                                img: ''
                            })
                            closeDialog()
                        }}><Icon type="download" />
                            下载素材</Button>
                    </div>
                </div>
            </Overlay>
        )
    }
}

export default SeeQrcode;
