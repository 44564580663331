import React, { Fragment } from 'react'
import { Button, Icon, Select, Table, Pagination, Dialog, Grid, DatePicker } from '@alifd/theme-14191'
import moment from 'moment'
import "./index.scss"
//添加编辑订单
import EditCoffee from './components/EditCoffee'
//型号管理
import Model from './components/Model'
//查看而为am
import SeeAssets from './components/SeeAssets'
import { getMainContainer } from '../../Common/common'

import { getFinancialList, getLauchList } from '../../../config/axios/apis'
import { exportXlsx } from '../../../config/export'
import HeaderTitle from '../../../components/HeaderTitle'

const orderStatusCss = ["nopay", "audit", "faild", "review", "put", "complate"];
const orderStatus = ["未支付", "审核中", "审核失败", "重新审核中", "投放中", "已完成"];

class Order extends React.Component {

    constructor() {
        super();
        this.state = {
            dataSource: [],
            statusList: [{
                id: '全部',
                name: "全部"
            }],//订单状态
            ruleList: [{
                id: '全部',
                name: "全部"
            }],//投放规则
            modelShow: false,//型号管理
            editCoffee: false,//新增编辑咖啡机
            orderModel: undefined,//正在编辑的
            seeAssetsShow: false,
            totalMoney: 0,
            //查询信息
            count_page: 0,
            start_time: "", end_time: '', order_status: '', launch_id: '',
            page: 1,
            limit: 15
        }
    }


    // 获取所有的投放规则
    getToufangList = () => {
        getLauchList('', 1, 1, 0).then((res) => {
            var ruleList = [];
            res.data.forEach(item => {
                ruleList.push({
                    id: item.id,
                    name: item.launch_type == 1 ? item.month : `${item.count_frequency}次（赠送${item.give_frequency}） ${item.amount}`,
                });
            })
            this.setState({
                ruleList
            })
        })
    }

    //刷新查询数据
    refresh = () => {
        // var tempAll = [];
        // for (var i = 0; i < 15; i++) {
        //     var tempdata = {
        //         number: '' + i,
        //         time: '2021-12-12 12:00:00',
        //         paytime: '2021-12-12 13:00:00',
        //         money: 50,
        //         pos: '位置',
        //         rule: "规则",
        //         status: "状态",
        //     };
        //     tempAll.push(tempdata)
        // }
        const { start_time, end_time, order_status, launch_id, page, limit } = this.state;
        getFinancialList(start_time, end_time, order_status, launch_id, page, limit).then((res) => {
            var tempAll = [];
            res.data.list.forEach((item, i) => {
                // console.log(item)
                var pos = [];
                item.karol_list.forEach(k => {
                    pos.push(`${k.shop}-${k.region}-${k.layer}-${k.karol_number}`)
                })

                tempAll.push({
                    number: item.order_number,//'' + i,
                    time: moment(item.order_time * 1000).format("YYYY-MM-DD HH:mm:ss"),
                    paytime: item.time_end ? moment(item.time_end * 1000).format("YYYY-MM-DD HH:mm:ss") : '',
                    money: item.order_money,//50,
                    pos,
                    rule: item.launch_type == 2 ? '按次数' : "按月",
                    status: item.order_status,//"状态",
                })
            })
            this.setState({
                dataSource: tempAll,
                count_page: res.count_page,
                totalMoney: res.data.order_amount,
            })
            this.forceUpdate();
        })
    }

    //删除咖啡机
    delCoffee = (id) => {
        Dialog.confirm({
            title: '提示',
            content: '确定删除吗？',
            messageProps: {
                type: 'error'
            },
            onOk: () => {
                doCoffee('del', id).then(() => {
                    this.refresh();
                })
                // doModel('del', id, '').then(() => {
                //     this.refresh();
                // })
            },
        });
    }

    //导出
    export = () => {
        const { start_time, end_time, order_status, launch_id, page, limit } = this.state;
        getFinancialList(start_time, end_time, order_status, launch_id, page, limit, true);
        // var belonger_id = this.state.belonger_id;
        // var search = this.state.searchInfo;
        // // window.open(`http://modoka.dysense.cn/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`)
        // exportXlsx(`/admin/Machine/getMachineList?belonger_id=${belonger_id}&search=${search}&excel=1`, '咖啡机列表');
    }

    componentDidMount() {
        this.refresh();
        this.getToufangList()

        this.setState({
            // dataSource: tempAll,
            container: getMainContainer()
        })
    }


    //点击新增或编辑
    editCoffeeHandle = (orderModel) => {
        this.state.container.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            editCoffee: true,
            orderModel: orderModel
        })
    }
    //关闭Dialog
    closeDialog = () => {
        this.refresh();
        this.setState({
            editCoffee: false,
            modelShow: false,
            seeAssetsShow: false
        })
    }

    //订单状态
    orderStatus = (value, index, record) => {
        return <>
            <div className={orderStatusCss[record.status]}>{orderStatus[record.status]}</div>
        </>
    }
    //投放机器
    getMachine = (value, index, record) => {
        return <>
            {
                record.pos.map(item => {
                    return <div>{item}</div>
                })
            }
        </>
    }
    //查看素材
    seeAssets = (value, index, record) => {
        this.setState({
            seeAssetsShow: true,
            orderModel: record
        })
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.seeAssets(value, index, record);
                }}>查看素材</Button>
                <Button text>通过审核</Button>
                <Button text>驳回审核</Button>
            </div >
        )
    }


    render() {
        const { dataSource, orderModel, searchInfo, ruleList, page, count_page, limit, statusList, totalMoney } = this.state;

        return (
            <Fragment>
                <HeaderTitle>财务统计</HeaderTitle>
                <div className="order">
                    {/* 头部 */}
                    <Grid.Row className="header">
                        <Grid.Col span={8}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={4}>
                                    <span className="search-label">投放时间</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <DatePicker.RangePicker defaultVisibleMonth={() => moment().add(-1, 'months')} onChange={(v) => {
                                        var start_time = '';
                                        var end_time = '';
                                        if (v[0]) {
                                            start_time = v[0]._d.getTime() / 1000
                                        }
                                        if (v[1]) {
                                            end_time = v[1]._d.getTime() / 1000
                                        }
                                        this.setState({
                                            start_time,
                                            end_time
                                        })
                                    }} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={6}>
                                    <span className="search-label">订单状态</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            order_status: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            orderStatus.map((item, index) => {
                                                return (
                                                    <Select.Option value={index} key={index}>{item}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Grid.Row className="search-item">
                                <Grid.Col span={6}>
                                    <span className="search-label">投放规则</span>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Select style={{ width: '100%' }} onChange={(v) => {
                                        this.setState({
                                            launch_id: v
                                        })
                                    }}>
                                        <Select.Option value=''>请选择</Select.Option>
                                        {
                                            ruleList.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Grid.Row>
                                <Grid.Col span={2} />
                                <Grid.Col span={6}>
                                    <Button type="primary" onClick={() => {
                                        this.setState({
                                            page: 1
                                        }, this.refresh)
                                    }}>搜索</Button>
                                </Grid.Col>
                                <Grid.Col span={16}>
                                    <Button type="primary" style={{
                                        float: 'right'
                                    }} onClick={() => {
                                        this.export()
                                    }}>导出Excel</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                    <div className='all-number' style={{
                        marginTop: '20px'
                    }}>
                        <span style={{
                            "font-size": '14px',
                            "font-family": 'PingFangSC-Regular, PingFang SC',
                            "font-weight": 400,
                            "color": '#666666',
                            "line-height": '22px',
                        }}>订单总金额</span><span style={{
                            "font-size": '30px',
                            "font-family": 'PingFangSC-Semibold, PingFang SC',
                            "font-weight": 600,
                            "color": '#FF8A65',
                            "line-height": '42px',
                            marginLeft: '16px'
                        }}>{totalMoney}</span>
                    </div>
                    {/* 表格 */}
                    <div className="content">
                        <Table id='table' hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="订单编号" dataIndex="number" width={160} />
                            <Table.Column title="下单时间" dataIndex="time" width={150} />
                            <Table.Column title="支付时间" dataIndex="paytime" width={150} />
                            <Table.Column title="订单金额" dataIndex="money" width={110} />
                            <Table.Column title="投放机位" dataIndex="pos" width={110} cell={this.getMachine} />
                            <Table.Column title="投放规则" dataIndex="rule" width={110} />
                            <Table.Column title="订单状态" dataIndex="status" width={110} cell={this.orderStatus} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>
                </div >
            </Fragment>
        )
    }
}
export default Order;

