import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@alifd/theme-14191';
import logo from '../../../../static/images/login/logo.png'
import './index.scss';
import store from "../../../../config/store";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uname: ''
    }
  }

  componentDidMount() {
    var str = store.get("login_info");
    if (str) {
      var info = JSON.parse(str)
      this.setState({
        uname: info.uname
      })
    }
  }

  handleExit = () => {
    store.clearAll();
    this.props.history.push('/user/login');
  }
  render() {
    const { location = {} } = this.props;
    const { pathname } = location;
    const { uname } = this.state;
    // const uname = sessionStorage.getItem('uname');
    // const wname = sessionStorage.getItem('w_name');
    const footer = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="user-name" style={{ fontSize: '16px', fontWeight: 'bolder' }}>{uname}</span>
        <span className="sperator" />
        <span>
          <span onClick={this.handleExit} style={{ fontSize: '16px', fontWeight: 'bolder', cursor: 'pointer' }}>退出登录</span>
        </span>
      </div>);

    return (
      <div className="header-container">
        {/* <img src={logo} style={{
          width: '125px',
          height: "19px",
          display: 'inline-block',
        }} /> */}
        <span className='theme-color'>领客广告位投放管理后台</span>
        <div className="userinfo">
          <span className="user-name theme-color">
            {uname}
          </span>
          <Button type="secondary" onClick={this.handleExit}>退出登录</Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Header) 