import React, { Fragment } from 'react'
import { Button, Icon, Input, Select, Table, Pagination, Dialog, Grid } from '@alifd/theme-14191'
import { getMainContainer } from '../../Common/common'
import './index.scss'

//编辑
import EditInfo from "./EditInfo";

import { getKarolRecommendList } from '../../../config/axios/apis';
import HeaderTitle from '../../../components/HeaderTitle';

// const nums = []
class MaterialManager extends React.Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            editModel: false,//显示编辑
            editModelInfo: [],//当前编辑信息
            auditShow: false,//审批
            nums: [],
            //查询信息
            count_page: 0,
            searchInfo: '',
            page: 1,
            limit: 15,
        }
    }

    //刷新数据
    refresh = () => {
        var tempAll = [];
        const { page, limit } = this.state;
        getKarolRecommendList(page, limit).then((res) => {
            if (res.data) {
                console.log(res)
                res.data.forEach((item, index) => {
                    var model = {
                        id: item.id,
                        k_id: item.k_id,
                        name: item.karol_number,//"可可粉：" + index,
                        shop: item.shop,//'员工：' + index,
                        desc: item.shop_info,//"海底捞品牌于1994年创始于四川简阳,自1999年起逐步开拓西安、郑州、北京等市场。2015年开拓台湾地区市场,目前已开业门店10家。",
                        img: item.karol_img.startsWith('https://lk.dysense.cn') ? item.karol_img : "https://lk.dysense.cn" + item.karol_img,//'https://img0.baidu.com/it/u=3436810468,4123553368&fm=26&fmt=auto'
                    }
                    tempAll.push(model)
                })
                this.setState({
                    dataSource: tempAll,
                    count_page: res.count_page
                })
            }
        })
    }

    componentDidMount() {
        this.refresh();

        this.setState({
            container: getMainContainer()
        })
    }

    editPeople = (record) => {
        // var temp = undefined;
        // if (record) {
        //     console.log(record)
        // }
        this.setState({
            editModelInfo: record,
            editModel: true
        })
    }

    //关闭
    closeDialog = () => {
        this.refresh();
        this.setState({
            editModelInfo: undefined,
            editModel: false,
            auditShow: false
        })
    }
    //打开审批
    openAudit = () => {
        this.setState({
            auditShow: true
        })
    }

    //查看二维码
    imgShow = (value, index, record) => {
        return <img style={{ width: '50px' }} src={record.img} />
    }
    //操作
    operation = (value, index, record) => {
        return (
            <div className="operation" style={{ color: '#9F7558' }}>
                <Button text onClick={() => {
                    this.editPeople(record)
                }}>修改</Button>
            </div >
        )
    }

    render() {
        const { dataSource, editModel, editModelInfo, auditShow, nums, searchInfo, page, limit, count_page } = this.state;
        return (
            <Fragment>
                <HeaderTitle>广告机列表</HeaderTitle>
                <div className="people">
                    {/* 头部 */}
                    {/* <Grid.Row className="header">
                        <Grid.Col span={24}>
                            <Button type="primary" style={{ float: 'right' }} onClick={this.editInfo.bind(this, undefined)}>新增</Button>
                        </Grid.Col>
                    </Grid.Row> */}
                    {/* 表格 */}
                    <div className="content">
                        <Table hasBorder={false} dataSource={dataSource} useVirtual maxBodyHeight={450}>
                            <Table.Column title="机位名称" dataIndex="name" width={110} />
                            <Table.Column title="投放商家" dataIndex="shop" width={110} />
                            <Table.Column title="商家介绍" dataIndex="desc" width={110} />
                            <Table.Column title="广告机图片" dataIndex="img" width={110} cell={this.imgShow} />
                            <Table.Column title="操作" cell={this.operation} width={110} />
                        </Table>
                        <Pagination className="page-info" current={page} total={count_page} pageSize={limit} onChange={(current) => {
                            this.setState({
                                page: current
                            }, () => {
                                this.refresh()
                            })
                        }} />
                    </div>

                    <EditInfo editModel={editModel} closeDialog={this.closeDialog} editModelInfo={editModelInfo} />
                </div>
            </Fragment>
        )
    }
}
export default MaterialManager;

